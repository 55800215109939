import React, {MouseEventHandler} from 'react'

type SortOrder = "ascn" | "desc" 

const SortButton = ({sortOrder, columnKey, sortKey, columnHeader, onClick}:{
    sortOrder: SortOrder, 
    columnKey: string,
    sortKey: string, 
    columnHeader: string,
    onClick: MouseEventHandler
}) => {
    return (
        <th onClick={onClick}>
            {columnHeader}
            {columnKey !== "action" && 
                sortKey === columnKey && sortOrder &&
                (
                    sortKey === columnKey && sortOrder === "desc" ? 
                        <i className="fds-glyphs-arrow-down5 ms-10" /> : 
                        <i className="fds-glyphs-arrow-up5 ms-10"/>
                )
            }
        </th>
    )
}

export default SortButton