import React, { useEffect } from 'react';
import { Spinner } from "react-bootstrap";
import './Loader.scss';

import { useSelector } from 'react-redux';

export const Loader = () => {
    const loaderRef = useSelector((store: any) => store.loaderRef);

    useEffect(() => {
        if (localStorage.getItem("isRefreshCalled") !== "true") {
            localStorage.setItem("lastActive", new Date().toString());
        }
    }, [loaderRef])

    return (
        <React.Fragment>
            {loaderRef.length > 0 ?
                <React.Fragment>
                    <div className="wrap">
                        <div className="loadWrap">
                            <Spinner animation="border" role="status" variant="primary">
                                <span className="sr-only"></span>
                            </Spinner>
                            <div className="loadTxt">{loaderRef}</div>
                        </div>
                    </div>
                    <div className="loader"></div>
                </React.Fragment> :
                null
            }
        </React.Fragment>
    );
}
