export const groupingsDataRef = (state = [], action: any) => {
    switch (action.type) {
        case "GROUPINGS_DATA":
            return action.data;
        default:
            return state;
    }
};

export const groupingsOrgRef = (state:string = "" , action: any) => {
    switch (action.type) {
        case "GROUPINGS_ORG":
            return action.data;
        default:
            return state;
    }
};