import axios from 'axios';

declare const window: any;

export const exportReport = (report_name: string) => {
    return (dispatch: any) => {
        dispatch({ type: "LOADING", data: "Exporting Data" });
        axios.get(window.ENV.exportDomain + report_name, {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem("bearer"),
                'Content-Type': 'application/json'
            }
        }).then((response) => {
            console.log("RES:", response);
            dispatch({ type: "LOADING", data: "" });
            dispatch(downloadReport(report_name));
        }).catch(err => {
            console.log("ERROR:", err);
            dispatch({ type: "LOADING", data: "" });
            dispatch({ type: "STATUS", data: { type: "DANGER", title: "Error", message: `The server encountered an error and could not complete your request. (${err.message})` } });
        });
    }
};

export const downloadReport = (report_name: string) => {
    return (dispatch: any) => {
        dispatch({ type: "LOADING", data: "Loading Report Data" });
        axios.get(window.ENV.downloadDomain + report_name, {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem("bearer"),
                'Content-Type': 'application/json'
            },
        }).then((response) => {
            localStorage.removeItem("report_id");
            localStorage.removeItem("skip_report_id");
            if (response.data.message !== "Report not available") {
                window.open(response.data.message);
            } else {
                dispatch({ type: "STATUS", data: { type: "INFO", title: "Report Status", message: "Report Not Available or Not Generated." } });
            }
            dispatch({ type: "LOADING", data: "" });
        }).catch(err => {
            console.log("ERROR:", err);
            if (err.response?.status === 401) {
                localStorage.setItem("skip_report_id", 'T');
            }
            dispatch({ type: "LOADING", data: "" });
            dispatch({ type: "STATUS", data: { type: "DANGER", title: "Error", message: `The server encountered an error and could not complete your request. (${err.message})` } });
        });
    }
};
