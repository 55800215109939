import { isInternalAdminOrUser } from '../../okta/ls';
import { Const } from '../../components/utils';

export const customersRef = (
    state: any = {
        customer: { ...Const.DEFAULT_VIEW_AS, data_source: ["GLOBAL"], modules: [...Const.MODULES], data_content: [...Const.DATA_CONTENT] },
        customers: [],
        users: [],
        viewAs: isInternalAdminOrUser() ? { ...Const.DEFAULT_VIEW_AS } : {}
    },
    action: any) => {
    switch (action.type) {
        case "GET_CUSTOMER":
            return { ...state, customer: { ...action.data } };
        case "GET_CUSTOMERS":
            return { ...state, customers: [...action.data] };
        case "GET_USERS":
            return { ...state, users: [...action.data] };
        case "SET_VIEW_AS":
            return { ...state, viewAs: { ...state.viewAs, ...action.data } };
        default:
            return state;
    }
};