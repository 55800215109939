import React, { useState } from "react";
import { Modal, Button, Form, OverlayTrigger, Tooltip } from "react-bootstrap";
import * as FDS from "@arctravel/react-fds/lib";
import './modal.scss';

interface TemplateProps {
    show: boolean;
    onHide: any;
    onSave: any;
    itemData: any;
    method: string;
    initData?: any;
}

export const ImportantMessageModal = (props: TemplateProps) => {
    const { show, onHide, onSave, initData, itemData, method } = props;
    const [activeTitle, setActiveTitle] = useState(itemData)
    const [disableSubmit, setDisableSubmit] = useState(true)
    const [duplicateTitle, setDuplicateTitle] = useState('')

    const onTitleChange = (ev: any) => {
        setActiveTitle((prev: any) => {
            prev['title'] = ev.target.value;
            return { ...prev };
        })
        const queryObj = initData.filter((item: any) => item.id !== activeTitle.id && item.title.toLowerCase() === ev.target.value.toLowerCase())
        queryObj.length > 0 ? setDuplicateTitle(ev.target.value) : setDuplicateTitle('')
        Object.values(activeTitle).indexOf('') > 0 || queryObj.length > 0 ? setDisableSubmit(true) : setDisableSubmit(false)
    }

    const onInputChange = (ev: any) => {
        if (ev?.startDate && ev?.endDate) {
            setActiveTitle((prev: any) => {
                prev['active'] = ev
                return { ...prev }
            })
        } else {
            setActiveTitle((prev: any) => {
                prev[ev.target.id] = ev.target.value;
                return { ...prev };
            })
        }
        Object.values(activeTitle).indexOf('') > 0 || duplicateTitle.length ? setDisableSubmit(true) : setDisableSubmit(false)
    }

    const handleButtonClick = (btn: string) => {
        switch (btn) {
            case "Cancel":
                onHide();
                break;
            case "Submit":
                onSave(activeTitle);
                onHide();
                break;
            default:
                break
        }
    }

    return (
        <Modal aria-labelledby="contained-modal-title-vcenter" centered show={show} onHide={onHide}>
            <Modal.Header closeButton>
                <Modal.Title>{method} Messages</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <small>Enter message details to submit new important information.</small>
                <Form>
                    <Form.Group className="mt-3" controlId={'message_date'}>
                        <Form.Label>Active Date Range</Form.Label>
                        <FDS.FDSDatePicker
                            startDate={activeTitle.active.startDate}
                            endDate={activeTitle.active.endDate}
                            minDate={new Date().toLocaleDateString()}
                            selectsRange={true}
                            onChange={(ev) => { onInputChange(ev) }}
                        />
                    </Form.Group>

                    <Form.Group controlId="title" className="mt-3">
                        <Form.Label>Message Title *</Form.Label>
                        <Form.Control type="text" name="title" autoComplete="off" defaultValue={activeTitle.title} onChange={(ev) => { onTitleChange(ev); ev.target.value === '' && setDisableSubmit(true) }} />
                        {duplicateTitle.length > 0 && <Form.Text className="danger pb-1">Title (<b>{activeTitle.title.toUpperCase()}</b>) is already taken.</Form.Text>}
                    </Form.Group>

                    <Form.Group controlId="messages" className="mt-3">
                        <Form.Label>Message *</Form.Label>
                        <OverlayTrigger
                            placement="auto"
                            overlay={<Tooltip>character of limit 1000</Tooltip>}
                        >
                            <i className="fds-glyphs-info infoTxt" />
                        </OverlayTrigger>
                        <Form.Control as="textarea" name="messages" autoComplete="off" maxLength={1000} defaultValue={activeTitle.messages} onChange={(ev) => { onInputChange(ev); ev.target.value === '' && setDisableSubmit(true) }} />
                    </Form.Group>

                </Form>
            </Modal.Body>

            <Modal.Footer>
                <Button style={{ marginRight: 'auto' }} variant={'secondary'} onClick={() => handleButtonClick('Cancel')}>Cancel</Button>
                <Button disabled={disableSubmit} variant={'primary'} onClick={() => handleButtonClick('Submit')}>{method === 'Add' ? "Submit" : "Update"}</Button>
            </Modal.Footer>
        </Modal>
    );
};
