import * as FDS from "@arctravel/react-fds/lib";

declare const window: any;

export const ADMINTemplate: any = {
    reportName: "ADMIN",
    info: "Use the below options of data metrics, row and column data elements, and query filters to configure your desired report template. The combination of selections will be used to calculate your report data. These options are based on a trip-level (O&D) of aggregation and offer summarized or daily  metrics which can be queried using a combination of both travel and purchase periods.",
    lists: [
        {
            label: "Customer Information",
            colorName: "",
            wraps: [{
                name: "Customer Information",
                isTitle: true,
                showName: false,
                fields: []
            }, {
                name: "Customer Name",
                showName: false,
                fields: [{
                    name: "name",
                    label: "Customer Name",
                    type: "text",
                    placeholder: "Enter Customer Name"
                }, {
                    name: "number",
                    label: "Customer Account Number",
                    type: "text",
                    placeholder: "Enter Customer Account Number"
                }]
            }, {
                name: "account_type",
                showName: false,
                fields: [{
                    name: "account_type",
                    label: "Customer Account Type",
                    type: "select",
                    value: '',
                    options: [
                        { key: "DIRECTIONAL", value: "Airline", checked: true },
                        { key: "NON_DIRECTIONAL", value: "Agency", checked: true },
                        { key: "BI_DIRECTIONAL", value: "3rd Party Financial", checked: true },
                        { key: "BI_DIRECTIONAL_TOTAL", value: "3rd Party Non-Financial", checked: true },
                    ]
                }, {
                    name: "contract_period",
                    label: "Contract Period",
                    type: "date",
                    selectsRange: true,
                    startDate: [FDS.dateToText(new Date(), "/", true, false)],
                    endDate: [FDS.dateToText(new Date(), "/", true, false)],
                }]
            }, {
                name: "Geographic Filters",
                showName: true,
                type: 'option',
                condition: 'AND',
                fields: [{
                    name: "org",
                    tab: "Airport",
                    label: "By Airport",
                    type: "typeahead",
                    matchedOnly: true,
                    value: [],
                    labelKey: "description",
                    codeKey: "code",
                    color: "ap",
                    minChar: 0,
                    noTop: true,
                    show: true,
                    url: window.ENV.references.airport,
                    options: [],
                    localStorage: [],
                    placeholder: "Enter Geographic Code(s)"
                }, {
                    name: "region",
                    tab: "Region",
                    label: "By Region/State",
                    type: "tree",
                    value: [],
                    labelKey: "name",
                    codeKey: "code",
                    color: "r",
                    minChar: 0,
                    noTop: true,
                    show: false,
                    url: window.ENV.references.region,
                    options: [],
                    localStorage: [],
                    placeholder: "Enter Geographic Code(s)"
                }, {
                    name: "country",
                    tab: "Country",
                    label: "By Country",
                    type: "typeahead",
                    matchedOnly: true,
                    value: [],
                    labelKey: "name",
                    codeKey: "code",
                    color: "c",
                    minChar: 0,
                    noTop: true,
                    show: false,
                    url: window.ENV.references.country,
                    options: [],
                    localStorage: JSON.parse(localStorage.getItem("REF:" + window.ENV.references.country) || '[]'),
                    placeholder: "Enter Geographic Code(s)"
                }]
            }, {
                name: "Data Source",
                showName: false,
                fields: [{
                    name: "source",
                    label: "Data Source",
                    type: "typeahead",
                    matchedOnly: true,
                    value: [{ code: "1", description: "ASP Settled Data" }, { code: "2", description: "BSP Settled Data" }, { code: "3", description: "Contributed Direct" },
                    { code: "4", description: "Contributed Indirect" }],
                    labelKey: "description",
                    codeKey: "code",
                    hideKey: true,
                    required: true,
                    minChar: 0,
                    show: true,
                    individual: true,
                    options: [
                        { code: "1", description: "ASP Settled Data" },
                        { code: "2", description: "BSP Settled Data" },
                        { code: "3", description: "Contributed Direct" },
                        { code: "4", description: "Contributed Indirect" }
                    ],
                    placeholder: "Select Data Source(s)"
                }, {
                    name: "cabin_class",
                    label: "Cabin Class",
                    type: "select",
                    value: ["First Class"],
                    noSelect: true,
                    disabled: true,
                    options: [
                        { key: "First Class", value: "First Class", checked: true },
                        { key: "Business Class", value: "Business Class", checked: true },
                        { key: "Premium/Full Economy Class", value: "Premium/Full Economy Class", checked: true },
                        { key: "Discount Economy Class", value: "Discount Economy Class", checked: true },
                        { key: "Other Classes", value: "Other Classes", checked: true }
                    ],
                    placeholder: "Select"
                }]
            }]
        },
        {
            label: "User Information",
            colorName: "",
            wraps: [{
                name: "User Information",
                isTitle: true,
                showName: false,
                fields: []
            }, {
                name: "User Name",
                showName: false,
                fields: [{
                    name: "name",
                    label: "User Name",
                    type: "text",
                    placeholder: "Enter User Name"
                }, {
                    name: "title",
                    label: "Title",
                    type: "text",
                    placeholder: "Enter User Title"
                }, {
                    name: "department",
                    label: "Department",
                    type: "text",
                    placeholder: "Enter Department"
                }]
            }, {
                name: "role",
                showName: false,
                fields: [{
                    name: "role",
                    label: "User Role",
                    type: "select",
                    value: '',
                    options: [
                        { key: "DIRECTIONAL", value: "Airline", checked: true },
                        { key: "NON_DIRECTIONAL", value: "Agency", checked: true },
                        { key: "BI_DIRECTIONAL", value: "3rd Party Financial", checked: true },
                        { key: "BI_DIRECTIONAL_TOTAL", value: "3rd Party Non-Financial", checked: true },
                    ]
                }, {
                    name: "email",
                    label: "Email",
                    type: "text",
                    placeholder: "Enter Email"
                }, {
                    type: "blank"
                }]
            }]
        }
    ]
};
