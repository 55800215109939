export const notificationsRef = (state = [], action: any) => {
    switch (action.type) {
        case "NOTIFICATIONS":
            const prev: any = [...state];
            prev.unshift(action.data);
            return prev;
        case "REMOVE_NOTIFICATION":
            const k = state.filter((item: any) => {
                return item.reportName !== action.data;
            });
            return k;
        case "REMOVE_ALL_NOTIFICATIONS":
            return [];
        default:
            return state;
    }
};

export const notificationRef = (state = {}, action: any) => {
    switch (action.type) {
        case "N_STATUS":
            return action.data;
        default:
            return state;
    }
};