import { useState, useEffect } from 'react'
import { Modal, Button, Form } from 'react-bootstrap';

import { setSavedTemplatesAction } from '../../store/actions'
import { useSelector, useDispatch } from 'react-redux';
import { getOts, isInternalAdminOrUser } from '../../okta/ls';
import { Const } from '../utils';

/*
    Template Shared Values
    0: Private
    1: Not Available
    2: Shared with ORG only
    2 as A2: Shared with ARC only in UI
*/

interface SaveTemplateProps {
    ip: any,
    show: boolean,
    saveAs: boolean,
    type: string,
    savedTemplates: any[],
    selectedTemplate: any,
    onHide: any
}

const SaveTemplate = (props: SaveTemplateProps) => {
    const dispatch = useDispatch();
    const customersRef: any = useSelector((state: any) => state.customersRef);
    const [customer, setCustomer] = useState(customersRef.customer);
    const [viewAs, setViewAs] = useState({ entity: "", org: "", orgName: "" });
    const [favTemplate, setFavTemplate] = useState(false)

    const [saveIp, setSaveIp] = useState({
        template_name: "",
        template_description: "",
        shared: "0",
        user_org: ""
    });

    const checkDuplicateTemplateName = () => {

        const isDuplicate = props.savedTemplates
            .filter(item => {
                return item.template_name?.toLowerCase().trim() === saveIp.template_name?.toLowerCase().trim() &&
                    item.report_type?.toLowerCase() === props.type?.toLowerCase() &&
                    (item.user_org === saveIp.user_org ||
                        (item.user_org === customer.org && item.shared !== "0"))
            })?.length > 0 && (props.selectedTemplate?.template_name?.length <= 0 || props.saveAs)

        return isDuplicate ? true : false
    }

    const handleClose = () => {
        props.onHide();
    }

    const onInputChange = (ev: any) => {
        setSaveIp((prev: any) => {
            prev[ev.target.name] = ev.target.value;
            if (isInternalAdminOrUser()) {
                if (["0", "A2"].indexOf(saveIp.shared) >= 0) {
                    prev.user_org = Const.ARC_ROLE;
                } else {
                    prev.user_org = viewAs?.orgName;
                }
            } else {
                prev.user_org = (getOts()?.idToken?.orgName || getOts()?.accessToken?.claims?.org);
            }
            return { ...prev };
        });
    }

    const save = (ip: any) => {
        dispatch(setSavedTemplatesAction(ip, props.type));
    }

    const saveTemplate = () => {
        handleClose();

        const eg = { ...saveIp };
        if (isInternalAdminOrUser()) {
            if (["0", "A2"].indexOf(saveIp.shared) >= 0) {
                eg.user_org = Const.ARC_ROLE;
            } else {
                eg.user_org = viewAs?.org;
            }
        } else {
            eg.user_org = (getOts()?.idToken?.orgName || getOts()?.accessToken?.claims?.org);
        }

        eg.shared = eg.shared.replace("A", "");

        const obj = {
            template: {
                report_type: props.type.toUpperCase(),
                ...saveIp,
                user_org: eg.user_org,
                shared: eg.shared,
                favorite: ["AGENCY_PERFORMANCE", "BENCHMARK"].indexOf(props.type) >= 0 && favTemplate === true ? "true" : "false"
            },
            request: {
                ...props.ip
            }
        };

        save({ ...obj });
    }

    useEffect(() => {
        setViewAs(customersRef.viewAs);
    }, [customersRef.viewAs]);

    useEffect(() => {
        setCustomer(customersRef.customer);
    }, [customersRef.customer]);

    useEffect(() => {
        setSaveIp((prev: any) => {
            prev["template_name"] = "";
            prev["template_description"] = props.selectedTemplate.template_description ? props.selectedTemplate.template_description : "";
            return { ...prev };
        })
    }, [props.selectedTemplate]);

    useEffect(() => {
        setSaveIp({
            template_name: "",
            template_description: "",
            shared: "0",
            user_org: ""
        });
    }, [props.show]);

    return (
        <Modal aria-labelledby="contained-modal-title-vcenter" centered show={props.show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Save Template</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group controlId="nameIp">
                        <Form.Label>Template Name *</Form.Label>
                        <Form.Control type="text" name="template_name" autoComplete="off" value={saveIp.template_name} onChange={onInputChange} disabled={props.selectedTemplate?.template_name && !props.saveAs ? true : false} />
                        {checkDuplicateTemplateName() ?
                            <Form.Text className="danger pb-1">Template Name (<b>{saveIp.template_name.toUpperCase()}</b>) is already taken{saveIp.user_org ? " by " + saveIp.user_org : null}.</Form.Text> :
                            null
                        }
                    </Form.Group>

                    {["AGENCY_PERFORMANCE", "BENCHMARK"].indexOf(props.type) >= 0 &&
                        <Form.Group className="mt-3">
                            <Form.Label style={{ marginRight: "1rem" }}>Set Favorite:</Form.Label>
                            <Form.Switch
                                className="custom-toggle"
                                name="setFavorite"
                                id="setFavorite"
                                label=""
                                aria-label='Set Favorite Template'
                                checked={favTemplate}
                                onClick={() => { setFavTemplate(!favTemplate) }}
                            />
                        </Form.Group>}

                    <Form.Group className="mt-3" controlId="descriptionIp">
                        <Form.Label>Description</Form.Label>
                        <Form.Control type="text" name="template_description" autoComplete="off" value={saveIp.template_description} onChange={onInputChange} />
                    </Form.Group>
                    <Form.Group className="form-group mt-3" controlId="sharingIp">
                        <Form.Label>Sharing</Form.Label>
                        <div className="select">
                            <Form.Select aria-label='Select template sharing' name="shared" value={saveIp.shared} onChange={onInputChange}>
                                <option aria-label='private' value="0">Keep Private</option>
                                {
                                    isInternalAdminOrUser() ?
                                        <option aria-label='arc users' value="A2">Share with all ARC users</option> :
                                        null
                                }
                                {[viewAs.orgName, viewAs.org].indexOf(Const.ARC_ROLE) < 0 ? <option aria-label='all users' value="2">Share with all {viewAs.orgName || customer?.name || getOts()?.idToken?.orgName || getOts()?.accessToken?.claims?.org} users</option> : null}
                            </Form.Select>
                        </div>
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Cancel
                </Button>
                <Button variant="primary"
                    onClick={saveTemplate}
                    disabled={!saveIp.template_name?.trim() || checkDuplicateTemplateName()}>
                    Save
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default SaveTemplate;