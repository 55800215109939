const ARCOrgName = "Airlines Reporting Corporation";
const ProductName = "Discovery Point";
const ARCCI_ProductName = "Corporate Intelligence®";
const ARCCI = "ARCCI";

const Roles = {
    ARC_ROLE: "ARC",
    AGENCY: "AGENCY",
    AIRLINE: "AIRLINE",
    THIRDPARTY_FINANCIAL: "THIRDPARTY-FINANCIAL",
    THIRDPARTY_NON_FINANCIAL: "THIRDPARTY-NON-FINANCIAL"
};

const Users = {
    INTERNAL_ADMIN: "INTERNAL_ADMIN",
    INTERNAL_USER: "INTERNAL_USER",
    EXTERNAL_ADMIN: "EXTERNAL_ADMIN",
    EXTERNAL_USER: "EXTERNAL_USER"
};

export const Const = {
    ProductName,
    ARCCI,
    ARCCI_ProductName,
    ...Roles,
    ...Users,
    DEFAULT_VIEW_AS: { org: Roles.ARC_ROLE, orgName: ARCOrgName, entity: Roles.AIRLINE, role: Users.INTERNAL_ADMIN },
    MODULES: [],
    DATA_CONTENT: [],
    DEFAULT_ORG: Roles.ARC_ROLE,
    CUSTOMER_ENTITIES: [Roles.AIRLINE, Roles.AGENCY, Roles.THIRDPARTY_FINANCIAL, Roles.THIRDPARTY_NON_FINANCIAL]
};