// const oktaAuthConfig = {
//   // Note: If your app is configured to use the Implicit flow
//   // instead of the Authorization Code with Proof of Code Key Exchange (PKCE)
//   // you will need to add `pkce: false`
//   issuer: window.ENV.oktaConfig.authParams.issuer,
//   clientId: window.ENV.oktaConfig.clientId,
//   redirectUri: window.ENV.oktaConfig.redirectUri,
//   tokenManager: {
//     autoRenew: true,
//     autoRemove: false,
//   },
// };

// const oktaSignInConfig = {
//   baseUrl: window.ENV.oktaConfig.baseUrl,
//   clientId: window.ENV.oktaConfig.clientId,
//   redirectUri: window.ENV.oktaConfig.redirectUri,
//   authParams: window.ENV.oktaConfig.authParams,
//   features: {
//     registration: false,
//     rememberMe: true,
//   },
//   // Additional documentation on config options can be found at https://github.com/okta/okta-signin-widget#basic-config-options
// };
// console.log(oktaAuthConfig);
// export { oktaAuthConfig, oktaSignInConfig };

// authO Change
import { CacheLocation } from "@auth0/auth0-react";

declare const window: any;

const AUTH0_CACHE_LOCATION: CacheLocation | undefined = "localstorage";
const config = window.ENV.oktaConfig;

const auth0Config = {
  domain: config.domain,
  clientId: config.clientId,
  cacheLocation: AUTH0_CACHE_LOCATION,
  useRefreshTokens: true,
  scope: config.scope,
  authorizationParams: {
    redirect_uri: window.location.origin,
    ...(config.audience ? { audience: config.audience } : null),
  },
};

export { auth0Config };
