import axios from "axios";
import { getReferencesAction, getNotificationsAction } from '../../../store/actions';
import { clearLocalStorage, Const } from '../../../components/utils';
import { isInternalAdminOrUser } from '../../../okta/ls';

declare const window: any;

export const postCustomerAction = (org: string, obj: any) => {

    return async (dispatch: any) => {

        dispatch({ type: "LOADING", data: `Updating ${org} Subscription` })

        const headers = {
            Authorization: "Bearer " + localStorage.getItem("bearer"),
            "Content-Type": "application/json",
        };

        axios.post(window.ENV.customerDomain.replace("customers", (org + "/update")),
            { user: { user_id: obj.user_id }, customer: { ...obj } },
            { headers: headers }
        ).then((response: any) => {

            dispatch(getCustomersAction())

            dispatch({ type: "LOADING", data: "" })
        }).catch(err => {
            const t = (+new Date() - (+new Date(localStorage.getItem('expiresAt') + ""))) / 1000 / 60 / 60;
            if (err.response?.status === 401 && (isNaN(t) || t > 1)) {
                dispatch(getNotificationsAction(false));
                clearLocalStorage();
                setTimeout(() => {
                    window.location.href = "/";
                    window.location.reload();
                }, 1000);
            }
            dispatch({
                type: "GET_CUSTOMERS",
                data: [],
            });
            console.log("Error in Updating Customer Service:", err)
            dispatch({ type: "LOADING", data: "" })
            dispatch({ type: "STATUS", data: { type: "DANGER", title: "Error", message: `The server encountered an error and could not complete your request. (${err.message})` } });
        });
    };
}

// export const getCustomerAction = (org: string, isInit?: boolean | false) => {
//     return async (dispatch: any) => {

//         const customerPromise = axios.get(window.ENV.customerDomain.replace("customers", org), {
//             headers: {
//                 Authorization: "Bearer " + localStorage.getItem("bearer"),
//                 "Content-Type": "application/json",
//             }
//         });

//         customerPromise.then((response: any) => {
//             const res: any = isInternalAdminOrUser() ? response.data.result.filter((cus: any) => cus.org === getOts()?.idToken?.claims?.org)[0] || {} : response.data.result[0] || {}
//             if (!isInternalAdminOrUser()) {
//                 dispatch({
//                     type: "GET_CUSTOMER",
//                     data: res,
//                 });
//             }

//             if (isInit) {
//                 dispatch(getReferencesAction(res.entity === Const.AGENCY ? true : false));
//                 dispatch(getNotificationsAction(true));
//             }

//             dispatch({ type: "LOADING", data: "" })
//         }).catch(err => {
//             const t = (+new Date() - (+new Date(localStorage.getItem('expiresAt') + ""))) / 1000 / 60 / 60;
//             if (err.response?.status === 401 && (isNaN(t) || t > 1)) {
//                 dispatch(getNotificationsAction(false));
//                 clearLocalStorage();
//                 setTimeout(() => {
//                     window.location.href = "/";
//                     window.location.reload();
//                 }, 1000);
//             }
//             dispatch({
//                 type: "GET_CUSTOMERS",
//                 data: [],
//             });
//             console.log("Error in Customers Service:", err)
//             dispatch({ type: "LOADING", data: "" })
//             dispatch({ type: "STATUS", data: { type: "DANGER", title: "Error", message: `The server encountered an error and could not complete your request. (${err.message})` } });
//         });
//     };
// };

export const getCustomersAction = (isInit?: boolean | false) => {
    return async (dispatch: any) => {
        const customerPromise = axios.get(window.ENV.customerDomain, {
            headers: {
                Authorization: "Bearer " + localStorage.getItem("bearer"),
                "Content-Type": "application/json",
            }
        });

        customerPromise.then(async (response: any) => {
            // TODO Recheck
            const arc = response.data.result.filter((cust: any) => cust.org === Const.ARC_ROLE)?.[0];
            const custs = response.data.result.filter((cust: any) => cust.org !== Const.ARC_ROLE);

            const res: any = isInternalAdminOrUser() ? response.data.result.filter((cus: any) => cus.org === Const.ARC_ROLE)[0] || {} : response.data.result[0] || {}

            if (!isInternalAdminOrUser()) {
                dispatch({ type: "GET_CUSTOMER", data: res });
            }

            if (arc !== undefined) {
                await Const.CUSTOMER_ENTITIES.forEach((entity: string) => {
                    custs.unshift({ ...arc, entity: entity });
                });
            }

            dispatch({
                type: "GET_CUSTOMERS",
                data: custs,
            });

            if (isInit) {
                dispatch(getReferencesAction(res.entity === Const.AGENCY ? true : false));
                dispatch(getNotificationsAction(true));
            }

            dispatch({ type: "LOADING", data: "" })
        }).catch(err => {
            const t = (+new Date() - (+new Date(localStorage.getItem('expiresAt') + ""))) / 1000 / 60 / 60;
            if (err.response?.status === 401 && (isNaN(t) || t > 1)) {
                dispatch(getNotificationsAction(false));
                clearLocalStorage();
                setTimeout(() => {
                    window.location.href = "/";
                    window.location.reload();
                }, 1000);
            }
            dispatch({
                type: "GET_CUSTOMER",
                data: [],
            });
            dispatch({
                type: "GET_CUSTOMERS",
                data: [],
            });
            console.log("Error in Customers Service:", err)
            dispatch({ type: "LOADING", data: "" })
            dispatch({ type: "STATUS", data: { type: "DANGER", title: "Error", message: `The server encountered an error and could not complete your request. (${err.message})` } });
        });
    };
};

export const getUsersAction = (org: string) => {
    return async (dispatch: any) => {
        const userPromise = axios.get(window.ENV.customerDomain.replace("customers", isInternalAdminOrUser() ? (org + "/users") : "users"), {
            headers: {
                Authorization: "Bearer " + localStorage.getItem("bearer"),
                "Content-Type": "application/json",
            }
        });

        userPromise.then((response: any) => {
            ["DEV", "QA"].indexOf(window.ENV.type) >= 0 && console.log(response.data.result);
            dispatch({ type: "GET_USERS", data: response.data.result });
            dispatch({ type: "LOADING", data: "" })
        }).catch(err => {
            dispatch({ type: "GET_USERS", data: [] });
            console.log("Error in Customers Service:", err)
            dispatch({ type: "LOADING", data: "" })
            dispatch({ type: "STATUS", data: { type: "DANGER", title: "Error", message: `The server encountered an error and could not complete your request. (${err.message})` } });
        });
    };
};

export const saveUsersAction = (type: string, org: string, body: any) => {
    return async (dispatch: any) => {
        dispatch({ type: "LOADING", data: "Saving User Information" });

        const headers = {
            Authorization: "Bearer " + localStorage.getItem("bearer"),
            "Content-Type": "application/json",
        };

        delete body.created_on;
        delete body.last_updated_on;
        if (type !== "REACTIVE") {
            delete body.status;
        } else {
            type = "UPDATE";
        }

        let userPromise: any;

        if (type === "CREATE" || type === "UPDATE") {
            userPromise = axios.post(window.ENV.customerDomain.replace("customers", (org + "/" + type.toLowerCase())),
                { user: { ...body } },
                { headers: headers }
            );
        } else if (type === "DELETE" || type === "DEACTIVATED") {
            userPromise = axios.delete(window.ENV.customerDomain.replace("customers", org),
                {
                    headers: headers,
                    data: {
                        user: {
                            action: type === "DELETE" ? "Delete" : "Deactivate",
                            user_id: body.user_id
                        }
                    },
                }
            );
        }

        userPromise.then((response: any) => {
            if (response.data?.message) {
                dispatch({ type: "STATUS", data: { type: "INFO", title: "Info", message: response.data?.message } });
            }
            dispatch(getUsersAction(org));
            dispatch({ type: "LOADING", data: "" });
        }).catch((err: any) => {
            console.log("Error in Customers Service:", err)
            dispatch({ type: "LOADING", data: "" })
            dispatch({ type: "STATUS", data: { type: "DANGER", title: "Error", message: `The server encountered an error and could not complete your request. (${err.message})` } });
        });
    };
};