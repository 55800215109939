import React, { useState, useEffect, useRef } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';

interface CheckReportReadyProps {
    reportType: string;
    onReady: any;
}

export const CheckReportReady = (props: CheckReportReadyProps) => {
    const dispatch = useDispatch();
    const notificationRef = useSelector((store: any) => store.notificationRef);
    const onReady: any = useRef(props.onReady);
    const [showModal, setShowModal] = useState(false);

    useEffect(() => {
        if (notificationRef.body?.notificationType === "requestComplete") {
            const tor = onReady.current;
            tor({ body: notificationRef.body, reportType: props.reportType, statusCode: notificationRef?.statusCode });
            dispatch({ type: "N_STATUS", data: { type: "INFO", title: "Notification", message: "", body: {}, statusCode: 200 } });
        }
    }, [notificationRef, props.reportType, onReady, dispatch]);

    return (
        <>
            <Modal aria-labelledby="contained-modal-title-vcenter" centered show={showModal} onHide={() => { setShowModal(false) }}>
                <Modal.Header closeButton>
                    <Modal.Title>Report Generation Status</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Your report generation still in progress. You can wait or can check later in History when the report is ready.
                </Modal.Body>
                <Modal.Footer>
                    <Button data-testid="waitButton" aria-label='Wait' variant="primary" onClick={() => props.onReady()}>
                        I will Wait
                    </Button>
                    <Button data-testid="waitButton" aria-label='Wait' variant="secondary" onClick={() => props.onReady()}>
                        I will Check Later
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
};