export const statusRef = (state = [], action: any) => {
    switch (action.type) {
        case "STATUS":
            return action.data;
        default:
            return state;
    }
};

export const loaderRef = (state = "", action: any) => {
    switch (action.type) {
        case "LOADING":
            localStorage.setItem("lastActive", new Date().toString());
            return action.data;
        default:
            return state;
    }
};

export const loginRef = (state = {}, action: any) => {
    switch (action.type) {
        case "LOGIN":
            return action.data;
        case "LOGOUT":
             return action.data;
        default:
            return state;
    }
};