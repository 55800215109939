import React, { useEffect, useState } from 'react';
import { Form, Container, Row, Col, Button, Table, Dropdown, Alert, Breadcrumb } from 'react-bootstrap';
import * as FDS from '@arctravel/react-fds/lib';
import { useDispatch, useSelector } from 'react-redux';
import './messages.scss'
import { ImportantMessageModal } from '../modals/ImportantMessageModal';
import { saveAppMessageAction } from '../../store/actions';
import { DynamicPagination } from '../utils/pagination/DynamicPagination';
import { random } from '../utils';

export const Messages = () => {
  const dispatch = useDispatch();
  const releaseMessagesRef: any = useSelector((state: any) => state.messagesRef['release_Note']);
  const messagesRef = releaseMessagesRef ? [...releaseMessagesRef?.data] : [];
  const [showModal, setShowModal] = useState(false);
  const [msgCount, setMsgCount] = useState(0);
  const [messages, setMessages]: any = useState([])
  const [activeTitle, setActiveTitle]: any = useState({})
  const [method, setMethod] = useState<string>("")

  const itemPerPage = 5
  const [currentPage, setCurrentPage] = useState(1)

  useEffect(() => {
    releaseMessagesRef?.data?.length && setMessages([...releaseMessagesRef.data]);
    setMsgCount(releaseMessagesRef?.data.length);
  }, [releaseMessagesRef?.data])

  // Pagination Get currrent page
  const indexOfLastItem = currentPage * itemPerPage;
  const indexOfFirstItem = indexOfLastItem - itemPerPage
  const currentPost = messages.sort((a: any, b: any) => { return new Date(b.active.startDate[0]).getTime() - new Date(a.active.startDate[0]).getTime() }).slice(indexOfFirstItem, indexOfLastItem)

  const handleAddMessage = () => {
    setActiveTitle({
      id: `${Date.now() * Math.floor(random() * 100)}`,
      title: "",
      active: {
        startDate: [FDS.dateToText(new Date(), "/", true, false)],
        endDate: [FDS.dateToText(new Date(), "/", true, false)]
      },
      messages: "",
    });
    setMethod("Add");
    setShowModal(true);
  }

  const handleEdit = (msgObj: any) => {
    msgObj.messages = msgObj.messages.join("\n");
    setActiveTitle({ ...msgObj });
    setMethod("Edit");
    setShowModal(true)
  }

  const handleDelete = (del_msg: any) => {
    const saveMessages = messages.filter((msg: any) => msg.id !== del_msg.id)
    dispatch(saveAppMessageAction(saveMessages, "release_Note"))
    if (saveMessages.length <= 0) setMessages([])
  }

  const handleSubmit = (data: any) => {
    data.messages = data.messages?.split("\n").filter((msg: string) => msg.length > 0);
    if (method === "Add") {
      dispatch(saveAppMessageAction([data, ...messagesRef], "release_Note"))
    } else if (method === "Edit") {
      dispatch(saveAppMessageAction([...messagesRef.filter((msg: any) => msg.id !== data.id), data], "release_Note"))
    }
    handleClose()
  }

  const handleClose = () => {
    setShowModal(false)
    setActiveTitle({});
    setMethod("");
  }

  const handleSearchChanged = (e: any) => {
    const filterTitle = releaseMessagesRef?.data?.length && [...releaseMessagesRef.data].filter((msg: any) => (msg.title.toLowerCase() + ',' + msg.messages.toString())?.toLowerCase().indexOf(e.target.value?.toLowerCase()) >= 0)
    setMessages(filterTitle)
    setCurrentPage(1)
  }

  return (
    <FDS.FDSLevel mainTitle={"Important Messages Management"} subTitle={"View and manage customer-facing communications"} type="three" name="adminMessagePage">
      <Container>
        <Breadcrumb>
          <Breadcrumb.Item href="#/admin">Admin</Breadcrumb.Item>
          <Breadcrumb.Item active>Important Messages</Breadcrumb.Item>
        </Breadcrumb>
      </Container>
      {msgCount > 0 ?
        <FDS.FDSLevel.Centered>
          <Row>
            <Col>
              <Form.Group key="Filter by Name" className="mt-3" controlId="Filter by Title">
                <Form.Control aria-label='Search Messages' placeholder="Search Messages" defaultValue="" autoComplete="off" onChange={(ev) => handleSearchChanged(ev)} type="text" />
                <Form.Text>Search for message by title or message</Form.Text>
              </Form.Group>
            </Col>
            <Col style={{ textAlign: 'right' }}>
              <Button
                className="mt-5"
                aria-label='Add new message button'
                variant="primary"
                onClick={() => handleAddMessage()}>Add New Message</Button>
            </Col>
          </Row>
          {messages.length > 0 ?
            <React.Fragment>
              <Row className='mt-3'>
                <Col>
                  <Table className='fds-table table-striped'>
                    <thead>
                      <tr>
                        <th>Title</th>
                        <th>Important Messages</th>
                        <th>Start Date</th>
                        <th>End Date</th>
                        <th>Status</th>
                        <th style={{ textAlign: "center" }}>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {currentPost.length && [...currentPost]
                        .map((msg: any, i: number) => {
                          return (
                            <tr key={i}>
                              <td>{msg.title}</td>
                              <td>
                                <ol className='ps-3'>
                                  {(typeof msg.messages === "string" ? [] : msg.messages || []).map((message: string, m: number) => {
                                    return <li key={m}>
                                      {message}
                                    </li>
                                  })}
                                </ol>
                              </td>
                              <td>{msg.active?.startDate[0]}</td>
                              <td >{msg.active?.endDate[0]}</td>
                              <td className={new Date(msg.active?.startDate[0]) > new Date(new Date().setHours(0, 0, 0, 0)) ? "" : new Date(msg.active?.endDate[0]) < new Date(new Date().setHours(0, 0, 0, 0)) ? "danger" : "successTxt"}>{new Date(msg.active?.startDate[0]) > new Date(new Date().setHours(0, 0, 0, 0)) ? "Scheduled" : new Date(msg.active?.endDate[0]) < new Date(new Date().setHours(0, 0, 0, 0)) ? "Expired" : "Active"}</td>
                              <td className="editMenu">
                                <Dropdown data-testid="edit-menu-items">
                                  <Dropdown.Toggle data-testid="edit-menu-items-btn" variant="link"><i className="fds-glyphs-more2"></i></Dropdown.Toggle>

                                  <Dropdown.Menu data-testid="edit-menu-items-list">
                                    <Dropdown.Item href="#" onClick={(e) => handleEdit({ ...msg })} aria-label='Edit message'><i className="fds-glyphs-file-plus" />Edit</Dropdown.Item>
                                    <Dropdown.Item href="#" onClick={(e) => handleDelete({ ...msg })} aria-label='Delete message'><i className="fds-glyphs-bin2" />Delete</Dropdown.Item>
                                  </Dropdown.Menu>
                                </Dropdown>
                              </td>
                            </tr>)
                        })}
                    </tbody>
                  </Table>
                </Col>
              </Row>
              {messages.length > itemPerPage ? <Row>
                <Col style={{ textAlign: "center" }}>
                  <DynamicPagination itemPerPage={itemPerPage} totalItems={messages.length} onPageChange={(page: number) => setCurrentPage(page)} />
                </Col>
              </Row> : null}
            </React.Fragment>
            :
            <Alert data-testid="table-main-no-data" className="mt-3" variant="primary">
              <div className="icon"><i className="fds-glyphs-info2"></i></div>
              <div className="body">There are no records for this search criteria.</div>
            </Alert>
          }
        </FDS.FDSLevel.Centered> :
        <Container className='mt-5 pt-5'>
          <Alert data-testid="table-main-no-data" variant="primary">
            <div className="icon"><i className="fds-glyphs-info2"></i></div>
            <div className="body">
              There are currently no messages available. Add a new message <a href="/#" onClick={(ev: any) => { ev.preventDefault(); handleAddMessage(); return false; }}><b>here</b></a>.
            </div>
          </Alert>
        </Container>
      }
      <div className='p-3'></div>
      <>
        {showModal && <ImportantMessageModal show={showModal} method={method} onHide={handleClose} onSave={handleSubmit} itemData={activeTitle} initData={messagesRef} />}
      </>
    </FDS.FDSLevel >
  );
}