import { Const } from '../components/utils';

export const getOts = () => {
    return localStorage.getItem("okta-token-storage") ? JSON.parse(localStorage.getItem("okta-token-storage") + "") : {};
}

export const getOtsLength = () => {
    return Object.keys(localStorage.getItem("okta-token-storage") ? JSON.parse(localStorage.getItem("okta-token-storage") + "") : {}).length;
}

export const isInternalAdmin = () => {
    // return getOts()?.accessToken?.claims?.role?.indexOf(Const.INTERNAL_ADMIN) >= 0
    // authO Change
    return getOts()?.idToken?.role?.indexOf(Const.INTERNAL_ADMIN) >= 0
}

export const isExternalAdmin = () => {
    return getOts()?.idToken?.role?.indexOf(Const.EXTERNAL_ADMIN) >= 0
}

export const isInternalUser = () => {
    // return getOts()?.accessToken?.claims?.role?.indexOf(Const.INTERNAL_USER) >= 0
    // authO Change
    return getOts()?.idToken?.role?.indexOf(Const.INTERNAL_USER) >= 0
}

export const isInternalAdminOrUser = () => {
    // return getOts()?.accessToken?.claims?.role?.indexOf(Const.INTERNAL_ADMIN) >= 0 || getOts()?.accessToken?.claims?.role?.indexOf(Const.INTERNAL_USER) >= 0
    // authO Change
    return getOts()?.idToken?.role?.indexOf(Const.INTERNAL_ADMIN) >= 0 || getOts()?.idToken?.role?.indexOf(Const.INTERNAL_USER) >= 0
}