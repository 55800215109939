const carrierSaleTrend = {
	"results": [
		{
			"_domIntlCd": "A",
			"_grossSales": "0.00",
			"_mon": "1",
			"_monthName": "JAN",
			"_tranCnt": "0",
			"_yearMonth": "JAN 2014",
			"_yr": "2014",
			"__prefix": "ns2"
		},
		{
			"_domIntlCd": "D",
			"_grossSales": "0.00",
			"_mon": "1",
			"_monthName": "JAN",
			"_tranCnt": "0",
			"_yearMonth": "JAN 2014",
			"_yr": "2014",
			"__prefix": "ns2"
		},
		{
			"_domIntlCd": "I",
			"_grossSales": "0.00",
			"_mon": "1",
			"_monthName": "JAN",
			"_tranCnt": "0",
			"_yearMonth": "JAN 2014",
			"_yr": "2014",
			"__prefix": "ns2"
		},
		{
			"_domIntlCd": "A",
			"_grossSales": "0.00",
			"_mon": "2",
			"_monthName": "FEB",
			"_tranCnt": "0",
			"_yearMonth": "FEB 2014",
			"_yr": "2014",
			"__prefix": "ns2"
		},
		{
			"_domIntlCd": "D",
			"_grossSales": "0.00",
			"_mon": "2",
			"_monthName": "FEB",
			"_tranCnt": "0",
			"_yearMonth": "FEB 2014",
			"_yr": "2014",
			"__prefix": "ns2"
		},
		{
			"_domIntlCd": "I",
			"_grossSales": "0.00",
			"_mon": "2",
			"_monthName": "FEB",
			"_tranCnt": "0",
			"_yearMonth": "FEB 2014",
			"_yr": "2014",
			"__prefix": "ns2"
		},
		{
			"_domIntlCd": "A",
			"_grossSales": "0.00",
			"_mon": "3",
			"_monthName": "MAR",
			"_tranCnt": "0",
			"_yearMonth": "MAR 2014",
			"_yr": "2014",
			"__prefix": "ns2"
		},
		{
			"_domIntlCd": "D",
			"_grossSales": "0.00",
			"_mon": "3",
			"_monthName": "MAR",
			"_tranCnt": "0",
			"_yearMonth": "MAR 2014",
			"_yr": "2014",
			"__prefix": "ns2"
		},
		{
			"_domIntlCd": "I",
			"_grossSales": "0.00",
			"_mon": "3",
			"_monthName": "MAR",
			"_tranCnt": "0",
			"_yearMonth": "MAR 2014",
			"_yr": "2014",
			"__prefix": "ns2"
		},
		{
			"_domIntlCd": "A",
			"_grossSales": "0.00",
			"_mon": "4",
			"_monthName": "APR",
			"_tranCnt": "0",
			"_yearMonth": "APR 2014",
			"_yr": "2014",
			"__prefix": "ns2"
		},
		{
			"_domIntlCd": "D",
			"_grossSales": "0.00",
			"_mon": "4",
			"_monthName": "APR",
			"_tranCnt": "0",
			"_yearMonth": "APR 2014",
			"_yr": "2014",
			"__prefix": "ns2"
		},
		{
			"_domIntlCd": "I",
			"_grossSales": "0.00",
			"_mon": "4",
			"_monthName": "APR",
			"_tranCnt": "0",
			"_yearMonth": "APR 2014",
			"_yr": "2014",
			"__prefix": "ns2"
		},
		{
			"_domIntlCd": "A",
			"_grossSales": "0.00",
			"_mon": "5",
			"_monthName": "MAY",
			"_tranCnt": "0",
			"_yearMonth": "MAY 2014",
			"_yr": "2014",
			"__prefix": "ns2"
		},
		{
			"_domIntlCd": "D",
			"_grossSales": "0.00",
			"_mon": "5",
			"_monthName": "MAY",
			"_tranCnt": "0",
			"_yearMonth": "MAY 2014",
			"_yr": "2014",
			"__prefix": "ns2"
		},
		{
			"_domIntlCd": "I",
			"_grossSales": "0.00",
			"_mon": "5",
			"_monthName": "MAY",
			"_tranCnt": "0",
			"_yearMonth": "MAY 2014",
			"_yr": "2014",
			"__prefix": "ns2"
		},
		{
			"_domIntlCd": "A",
			"_grossSales": "0.00",
			"_mon": "6",
			"_monthName": "JUN",
			"_tranCnt": "0",
			"_yearMonth": "JUN 2014",
			"_yr": "2014",
			"__prefix": "ns2"
		},
		{
			"_domIntlCd": "D",
			"_grossSales": "0.00",
			"_mon": "6",
			"_monthName": "JUN",
			"_tranCnt": "0",
			"_yearMonth": "JUN 2014",
			"_yr": "2014",
			"__prefix": "ns2"
		},
		{
			"_domIntlCd": "I",
			"_grossSales": "0.00",
			"_mon": "6",
			"_monthName": "JUN",
			"_tranCnt": "0",
			"_yearMonth": "JUN 2014",
			"_yr": "2014",
			"__prefix": "ns2"
		},
		{
			"_domIntlCd": "A",
			"_grossSales": "0.00",
			"_mon": "7",
			"_monthName": "JUL",
			"_tranCnt": "0",
			"_yearMonth": "JUL 2014",
			"_yr": "2014",
			"__prefix": "ns2"
		},
		{
			"_domIntlCd": "D",
			"_grossSales": "0.00",
			"_mon": "7",
			"_monthName": "JUL",
			"_tranCnt": "0",
			"_yearMonth": "JUL 2014",
			"_yr": "2014",
			"__prefix": "ns2"
		},
		{
			"_domIntlCd": "I",
			"_grossSales": "0.00",
			"_mon": "7",
			"_monthName": "JUL",
			"_tranCnt": "0",
			"_yearMonth": "JUL 2014",
			"_yr": "2014",
			"__prefix": "ns2"
		},
		{
			"_domIntlCd": "A",
			"_grossSales": "0.00",
			"_mon": "8",
			"_monthName": "AUG",
			"_tranCnt": "0",
			"_yearMonth": "AUG 2014",
			"_yr": "2014",
			"__prefix": "ns2"
		},
		{
			"_domIntlCd": "D",
			"_grossSales": "0.00",
			"_mon": "8",
			"_monthName": "AUG",
			"_tranCnt": "0",
			"_yearMonth": "AUG 2014",
			"_yr": "2014",
			"__prefix": "ns2"
		},
		{
			"_domIntlCd": "I",
			"_grossSales": "0.00",
			"_mon": "8",
			"_monthName": "AUG",
			"_tranCnt": "0",
			"_yearMonth": "AUG 2014",
			"_yr": "2014",
			"__prefix": "ns2"
		},
		{
			"_domIntlCd": "A",
			"_grossSales": "0.00",
			"_mon": "9",
			"_monthName": "SEP",
			"_tranCnt": "0",
			"_yearMonth": "SEP 2014",
			"_yr": "2014",
			"__prefix": "ns2"
		},
		{
			"_domIntlCd": "D",
			"_grossSales": "0.00",
			"_mon": "9",
			"_monthName": "SEP",
			"_tranCnt": "0",
			"_yearMonth": "SEP 2014",
			"_yr": "2014",
			"__prefix": "ns2"
		},
		{
			"_domIntlCd": "I",
			"_grossSales": "0.00",
			"_mon": "9",
			"_monthName": "SEP",
			"_tranCnt": "0",
			"_yearMonth": "SEP 2014",
			"_yr": "2014",
			"__prefix": "ns2"
		},
		{
			"_domIntlCd": "A",
			"_grossSales": "0.00",
			"_mon": "10",
			"_monthName": "OCT",
			"_tranCnt": "0",
			"_yearMonth": "OCT 2014",
			"_yr": "2014",
			"__prefix": "ns2"
		},
		{
			"_domIntlCd": "D",
			"_grossSales": "0.00",
			"_mon": "10",
			"_monthName": "OCT",
			"_tranCnt": "0",
			"_yearMonth": "OCT 2014",
			"_yr": "2014",
			"__prefix": "ns2"
		},
		{
			"_domIntlCd": "I",
			"_grossSales": "0.00",
			"_mon": "10",
			"_monthName": "OCT",
			"_tranCnt": "0",
			"_yearMonth": "OCT 2014",
			"_yr": "2014",
			"__prefix": "ns2"
		},
		{
			"_domIntlCd": "A",
			"_grossSales": "0.00",
			"_mon": "11",
			"_monthName": "NOV",
			"_tranCnt": "0",
			"_yearMonth": "NOV 2014",
			"_yr": "2014",
			"__prefix": "ns2"
		},
		{
			"_domIntlCd": "D",
			"_grossSales": "0.00",
			"_mon": "11",
			"_monthName": "NOV",
			"_tranCnt": "0",
			"_yearMonth": "NOV 2014",
			"_yr": "2014",
			"__prefix": "ns2"
		},
		{
			"_domIntlCd": "I",
			"_grossSales": "0.00",
			"_mon": "11",
			"_monthName": "NOV",
			"_tranCnt": "0",
			"_yearMonth": "NOV 2014",
			"_yr": "2014",
			"__prefix": "ns2"
		},
		{
			"_domIntlCd": "A",
			"_grossSales": "0.00",
			"_mon": "12",
			"_monthName": "DEC",
			"_tranCnt": "0",
			"_yearMonth": "DEC 2014",
			"_yr": "2014",
			"__prefix": "ns2"
		},
		{
			"_domIntlCd": "D",
			"_grossSales": "0.00",
			"_mon": "12",
			"_monthName": "DEC",
			"_tranCnt": "0",
			"_yearMonth": "DEC 2014",
			"_yr": "2014",
			"__prefix": "ns2"
		},
		{
			"_domIntlCd": "I",
			"_grossSales": "0.00",
			"_mon": "12",
			"_monthName": "DEC",
			"_tranCnt": "0",
			"_yearMonth": "DEC 2014",
			"_yr": "2014",
			"__prefix": "ns2"
		},
		{
			"_domIntlCd": "A",
			"_grossSales": "1097321246.00",
			"_mon": "1",
			"_monthName": "JAN",
			"_tranCnt": "2167040",
			"_yearMonth": "JAN 2015",
			"_yr": "2015",
			"__prefix": "ns2"
		},
		{
			"_domIntlCd": "D",
			"_grossSales": "665470301.00",
			"_mon": "1",
			"_monthName": "JAN",
			"_tranCnt": "1649260",
			"_yearMonth": "JAN 2015",
			"_yr": "2015",
			"__prefix": "ns2"
		},
		{
			"_domIntlCd": "I",
			"_grossSales": "429690683.00",
			"_mon": "1",
			"_monthName": "JAN",
			"_tranCnt": "500162",
			"_yearMonth": "JAN 2015",
			"_yr": "2015",
			"__prefix": "ns2"
		},
		{
			"_domIntlCd": "A",
			"_grossSales": "1084626350.00",
			"_mon": "2",
			"_monthName": "FEB",
			"_tranCnt": "2098747",
			"_yearMonth": "FEB 2015",
			"_yr": "2015",
			"__prefix": "ns2"
		},
		{
			"_domIntlCd": "D",
			"_grossSales": "649956812.00",
			"_mon": "2",
			"_monthName": "FEB",
			"_tranCnt": "1582261",
			"_yearMonth": "FEB 2015",
			"_yr": "2015",
			"__prefix": "ns2"
		},
		{
			"_domIntlCd": "I",
			"_grossSales": "431883872.00",
			"_mon": "2",
			"_monthName": "FEB",
			"_tranCnt": "499144",
			"_yearMonth": "FEB 2015",
			"_yr": "2015",
			"__prefix": "ns2"
		},
		{
			"_domIntlCd": "A",
			"_grossSales": "1180283432.00",
			"_mon": "3",
			"_monthName": "MAR",
			"_tranCnt": "2273637",
			"_yearMonth": "MAR 2015",
			"_yr": "2015",
			"__prefix": "ns2"
		},
		{
			"_domIntlCd": "D",
			"_grossSales": "721177934.00",
			"_mon": "3",
			"_monthName": "MAR",
			"_tranCnt": "1725145",
			"_yearMonth": "MAR 2015",
			"_yr": "2015",
			"__prefix": "ns2"
		},
		{
			"_domIntlCd": "I",
			"_grossSales": "455971218.00",
			"_mon": "3",
			"_monthName": "MAR",
			"_tranCnt": "530085",
			"_yearMonth": "MAR 2015",
			"_yr": "2015",
			"__prefix": "ns2"
		},
		{
			"_domIntlCd": "A",
			"_grossSales": "1158171252.00",
			"_mon": "4",
			"_monthName": "APR",
			"_tranCnt": "2236723",
			"_yearMonth": "APR 2015",
			"_yr": "2015",
			"__prefix": "ns2"
		},
		{
			"_domIntlCd": "D",
			"_grossSales": "711360569.00",
			"_mon": "4",
			"_monthName": "APR",
			"_tranCnt": "1715001",
			"_yearMonth": "APR 2015",
			"_yr": "2015",
			"__prefix": "ns2"
		},
		{
			"_domIntlCd": "I",
			"_grossSales": "444051610.00",
			"_mon": "4",
			"_monthName": "APR",
			"_tranCnt": "503768",
			"_yearMonth": "APR 2015",
			"_yr": "2015",
			"__prefix": "ns2"
		},
		{
			"_domIntlCd": "A",
			"_grossSales": "2309514025.00",
			"_mon": "5",
			"_monthName": "MAY",
			"_tranCnt": "4314159",
			"_yearMonth": "MAY 2015",
			"_yr": "2015",
			"__prefix": "ns2"
		},
		{
			"_domIntlCd": "D",
			"_grossSales": "1432660848.00",
			"_mon": "5",
			"_monthName": "MAY",
			"_tranCnt": "3318157",
			"_yearMonth": "MAY 2015",
			"_yr": "2015",
			"__prefix": "ns2"
		},
		{
			"_domIntlCd": "I",
			"_grossSales": "871939050.00",
			"_mon": "5",
			"_monthName": "MAY",
			"_tranCnt": "961199",
			"_yearMonth": "MAY 2015",
			"_yr": "2015",
			"__prefix": "ns2"
		},
		{
			"_domIntlCd": "A",
			"_grossSales": "2329843003.00",
			"_mon": "6",
			"_monthName": "JUN",
			"_tranCnt": "4651862",
			"_yearMonth": "JUN 2015",
			"_yr": "2015",
			"__prefix": "ns2"
		},
		{
			"_domIntlCd": "D",
			"_grossSales": "1474081760.00",
			"_mon": "6",
			"_monthName": "JUN",
			"_tranCnt": "3617136",
			"_yearMonth": "JUN 2015",
			"_yr": "2015",
			"__prefix": "ns2"
		},
		{
			"_domIntlCd": "I",
			"_grossSales": "850343586.00",
			"_mon": "6",
			"_monthName": "JUN",
			"_tranCnt": "983355",
			"_yearMonth": "JUN 2015",
			"_yr": "2015",
			"__prefix": "ns2"
		},
		{
			"_domIntlCd": "A",
			"_grossSales": "2305802246.00",
			"_mon": "7",
			"_monthName": "JUL",
			"_tranCnt": "4692308",
			"_yearMonth": "JUL 2015",
			"_yr": "2015",
			"__prefix": "ns2"
		},
		{
			"_domIntlCd": "D",
			"_grossSales": "1466817463.00",
			"_mon": "7",
			"_monthName": "JUL",
			"_tranCnt": "3680772",
			"_yearMonth": "JUL 2015",
			"_yr": "2015",
			"__prefix": "ns2"
		},
		{
			"_domIntlCd": "I",
			"_grossSales": "833515831.00",
			"_mon": "7",
			"_monthName": "JUL",
			"_tranCnt": "961838",
			"_yearMonth": "JUL 2015",
			"_yr": "2015",
			"__prefix": "ns2"
		},
		{
			"_domIntlCd": "A",
			"_grossSales": "2304054062.00",
			"_mon": "8",
			"_monthName": "AUG",
			"_tranCnt": "4982648",
			"_yearMonth": "AUG 2015",
			"_yr": "2015",
			"__prefix": "ns2"
		},
		{
			"_domIntlCd": "D",
			"_grossSales": "1429467229.00",
			"_mon": "8",
			"_monthName": "AUG",
			"_tranCnt": "3890315",
			"_yearMonth": "AUG 2015",
			"_yr": "2015",
			"__prefix": "ns2"
		},
		{
			"_domIntlCd": "I",
			"_grossSales": "867519491.00",
			"_mon": "8",
			"_monthName": "AUG",
			"_tranCnt": "1043977",
			"_yearMonth": "AUG 2015",
			"_yr": "2015",
			"__prefix": "ns2"
		},
		{
			"_domIntlCd": "A",
			"_grossSales": "2546915708.00",
			"_mon": "9",
			"_monthName": "SEP",
			"_tranCnt": "5409365",
			"_yearMonth": "SEP 2015",
			"_yr": "2015",
			"__prefix": "ns2"
		},
		{
			"_domIntlCd": "D",
			"_grossSales": "1632660490.00",
			"_mon": "9",
			"_monthName": "SEP",
			"_tranCnt": "4325351",
			"_yearMonth": "SEP 2015",
			"_yr": "2015",
			"__prefix": "ns2"
		},
		{
			"_domIntlCd": "I",
			"_grossSales": "906936661.00",
			"_mon": "9",
			"_monthName": "SEP",
			"_tranCnt": "1034741",
			"_yearMonth": "SEP 2015",
			"_yr": "2015",
			"__prefix": "ns2"
		},
		{
			"_domIntlCd": "A",
			"_grossSales": "3153512759.00",
			"_mon": "10",
			"_monthName": "OCT",
			"_tranCnt": "6944350",
			"_yearMonth": "OCT 2015",
			"_yr": "2015",
			"__prefix": "ns2"
		},
		{
			"_domIntlCd": "D",
			"_grossSales": "2155663026.00",
			"_mon": "10",
			"_monthName": "OCT",
			"_tranCnt": "5661340",
			"_yearMonth": "OCT 2015",
			"_yr": "2015",
			"__prefix": "ns2"
		},
		{
			"_domIntlCd": "I",
			"_grossSales": "989256950.00",
			"_mon": "10",
			"_monthName": "OCT",
			"_tranCnt": "1201899",
			"_yearMonth": "OCT 2015",
			"_yr": "2015",
			"__prefix": "ns2"
		},
		{
			"_domIntlCd": "A",
			"_grossSales": "2662252395.00",
			"_mon": "11",
			"_monthName": "NOV",
			"_tranCnt": "6166945",
			"_yearMonth": "NOV 2015",
			"_yr": "2015",
			"__prefix": "ns2"
		},
		{
			"_domIntlCd": "D",
			"_grossSales": "1829154272.00",
			"_mon": "11",
			"_monthName": "NOV",
			"_tranCnt": "4998357",
			"_yearMonth": "NOV 2015",
			"_yr": "2015",
			"__prefix": "ns2"
		},
		{
			"_domIntlCd": "I",
			"_grossSales": "825159121.00",
			"_mon": "11",
			"_monthName": "NOV",
			"_tranCnt": "1089264",
			"_yearMonth": "NOV 2015",
			"_yr": "2015",
			"__prefix": "ns2"
		},
		{
			"_domIntlCd": "A",
			"_grossSales": "2407995774.00",
			"_mon": "12",
			"_monthName": "DEC",
			"_tranCnt": "5820729",
			"_yearMonth": "DEC 2015",
			"_yr": "2015",
			"__prefix": "ns2"
		},
		{
			"_domIntlCd": "D",
			"_grossSales": "1654408350.00",
			"_mon": "12",
			"_monthName": "DEC",
			"_tranCnt": "4712333",
			"_yearMonth": "DEC 2015",
			"_yr": "2015",
			"__prefix": "ns2"
		},
		{
			"_domIntlCd": "I",
			"_grossSales": "744225047.00",
			"_mon": "12",
			"_monthName": "DEC",
			"_tranCnt": "1016194",
			"_yearMonth": "DEC 2015",
			"_yr": "2015",
			"__prefix": "ns2"
		},
		{
			"_domIntlCd": "A",
			"_grossSales": "3314916349.00",
			"_mon": "1",
			"_monthName": "JAN",
			"_tranCnt": "7658670",
			"_yearMonth": "JAN 2016",
			"_yr": "2016",
			"__prefix": "ns2"
		},
		{
			"_domIntlCd": "D",
			"_grossSales": "2208157991.00",
			"_mon": "1",
			"_monthName": "JAN",
			"_tranCnt": "6110967",
			"_yearMonth": "JAN 2016",
			"_yr": "2016",
			"__prefix": "ns2"
		},
		{
			"_domIntlCd": "I",
			"_grossSales": "1097227910.00",
			"_mon": "1",
			"_monthName": "JAN",
			"_tranCnt": "1463227",
			"_yearMonth": "JAN 2016",
			"_yr": "2016",
			"__prefix": "ns2"
		},
		{
			"_domIntlCd": "A",
			"_grossSales": "3450064386.00",
			"_mon": "2",
			"_monthName": "FEB",
			"_tranCnt": "7580459",
			"_yearMonth": "FEB 2016",
			"_yr": "2016",
			"__prefix": "ns2"
		},
		{
			"_domIntlCd": "D",
			"_grossSales": "2321192093.00",
			"_mon": "2",
			"_monthName": "FEB",
			"_tranCnt": "6039036",
			"_yearMonth": "FEB 2016",
			"_yr": "2016",
			"__prefix": "ns2"
		},
		{
			"_domIntlCd": "I",
			"_grossSales": "1120521613.00",
			"_mon": "2",
			"_monthName": "FEB",
			"_tranCnt": "1478626",
			"_yearMonth": "FEB 2016",
			"_yr": "2016",
			"__prefix": "ns2"
		},
		{
			"_domIntlCd": "A",
			"_grossSales": "3669091859.00",
			"_mon": "3",
			"_monthName": "MAR",
			"_tranCnt": "8082321",
			"_yearMonth": "MAR 2016",
			"_yr": "2016",
			"__prefix": "ns2"
		},
		{
			"_domIntlCd": "D",
			"_grossSales": "2492027169.00",
			"_mon": "3",
			"_monthName": "MAR",
			"_tranCnt": "6416979",
			"_yearMonth": "MAR 2016",
			"_yr": "2016",
			"__prefix": "ns2"
		},
		{
			"_domIntlCd": "I",
			"_grossSales": "1167376567.00",
			"_mon": "3",
			"_monthName": "MAR",
			"_tranCnt": "1598815",
			"_yearMonth": "MAR 2016",
			"_yr": "2016",
			"__prefix": "ns2"
		},
		{
			"_domIntlCd": "A",
			"_grossSales": "3477908527.00",
			"_mon": "4",
			"_monthName": "APR",
			"_tranCnt": "8195423",
			"_yearMonth": "APR 2016",
			"_yr": "2016",
			"__prefix": "ns2"
		},
		{
			"_domIntlCd": "D",
			"_grossSales": "2365837609.00",
			"_mon": "4",
			"_monthName": "APR",
			"_tranCnt": "6594485",
			"_yearMonth": "APR 2016",
			"_yr": "2016",
			"__prefix": "ns2"
		},
		{
			"_domIntlCd": "I",
			"_grossSales": "1101821101.00",
			"_mon": "4",
			"_monthName": "APR",
			"_tranCnt": "1519656",
			"_yearMonth": "APR 2016",
			"_yr": "2016",
			"__prefix": "ns2"
		},
		{
			"_domIntlCd": "A",
			"_grossSales": "3502361374.00",
			"_mon": "5",
			"_monthName": "MAY",
			"_tranCnt": "8088376",
			"_yearMonth": "MAY 2016",
			"_yr": "2016",
			"__prefix": "ns2"
		},
		{
			"_domIntlCd": "D",
			"_grossSales": "2237278614.00",
			"_mon": "5",
			"_monthName": "MAY",
			"_tranCnt": "6046364",
			"_yearMonth": "MAY 2016",
			"_yr": "2016",
			"__prefix": "ns2"
		},
		{
			"_domIntlCd": "I",
			"_grossSales": "996668606.00",
			"_mon": "5",
			"_monthName": "MAY",
			"_tranCnt": "1278690",
			"_yearMonth": "MAY 2016",
			"_yr": "2016",
			"__prefix": "ns2"
		},
		{
			"_domIntlCd": "A",
			"_grossSales": "3333376445.00",
			"_mon": "6",
			"_monthName": "JUN",
			"_tranCnt": "7658580",
			"_yearMonth": "JUN 2016",
			"_yr": "2016",
			"__prefix": "ns2"
		},
		{
			"_domIntlCd": "D",
			"_grossSales": "0.00",
			"_mon": "6",
			"_monthName": "JUN",
			"_tranCnt": "0",
			"_yearMonth": "JUN 2016",
			"_yr": "2016",
			"__prefix": "ns2"
		},
		{
			"_domIntlCd": "I",
			"_grossSales": "0.00",
			"_mon": "6",
			"_monthName": "JUN",
			"_tranCnt": "0",
			"_yearMonth": "JUN 2016",
			"_yr": "2016",
			"__prefix": "ns2"
		},
		{
			"_domIntlCd": "A",
			"_grossSales": "3078729580.00",
			"_mon": "7",
			"_monthName": "JUL",
			"_tranCnt": "7307275",
			"_yearMonth": "JUL 2016",
			"_yr": "2016",
			"__prefix": "ns2"
		},
		{
			"_domIntlCd": "D",
			"_grossSales": "0.00",
			"_mon": "7",
			"_monthName": "JUL",
			"_tranCnt": "0",
			"_yearMonth": "JUL 2016",
			"_yr": "2016",
			"__prefix": "ns2"
		},
		{
			"_domIntlCd": "I",
			"_grossSales": "0.00",
			"_mon": "7",
			"_monthName": "JUL",
			"_tranCnt": "0",
			"_yearMonth": "JUL 2016",
			"_yr": "2016",
			"__prefix": "ns2"
		},
		{
			"_domIntlCd": "A",
			"_grossSales": "3224866529.00",
			"_mon": "8",
			"_monthName": "AUG",
			"_tranCnt": "7859615",
			"_yearMonth": "AUG 2016",
			"_yr": "2016",
			"__prefix": "ns2"
		},
		{
			"_domIntlCd": "D",
			"_grossSales": "0.00",
			"_mon": "8",
			"_monthName": "AUG",
			"_tranCnt": "0",
			"_yearMonth": "AUG 2016",
			"_yr": "2016",
			"__prefix": "ns2"
		},
		{
			"_domIntlCd": "I",
			"_grossSales": "0.00",
			"_mon": "8",
			"_monthName": "AUG",
			"_tranCnt": "0",
			"_yearMonth": "AUG 2016",
			"_yr": "2016",
			"__prefix": "ns2"
		},
		{
			"_domIntlCd": "A",
			"_grossSales": "3171456709.00",
			"_mon": "9",
			"_monthName": "SEP",
			"_tranCnt": "7614663",
			"_yearMonth": "SEP 2016",
			"_yr": "2016",
			"__prefix": "ns2"
		},
		{
			"_domIntlCd": "D",
			"_grossSales": "0.00",
			"_mon": "9",
			"_monthName": "SEP",
			"_tranCnt": "0",
			"_yearMonth": "SEP 2016",
			"_yr": "2016",
			"__prefix": "ns2"
		},
		{
			"_domIntlCd": "I",
			"_grossSales": "0.00",
			"_mon": "9",
			"_monthName": "SEP",
			"_tranCnt": "0",
			"_yearMonth": "SEP 2016",
			"_yr": "2016",
			"__prefix": "ns2"
		},
		{
			"_domIntlCd": "A",
			"_grossSales": "3054621004.00",
			"_mon": "10",
			"_monthName": "OCT",
			"_tranCnt": "7433910",
			"_yearMonth": "OCT 2016",
			"_yr": "2016",
			"__prefix": "ns2"
		},
		{
			"_domIntlCd": "D",
			"_grossSales": "0.00",
			"_mon": "10",
			"_monthName": "OCT",
			"_tranCnt": "0",
			"_yearMonth": "OCT 2016",
			"_yr": "2016",
			"__prefix": "ns2"
		},
		{
			"_domIntlCd": "I",
			"_grossSales": "0.00",
			"_mon": "10",
			"_monthName": "OCT",
			"_tranCnt": "0",
			"_yearMonth": "OCT 2016",
			"_yr": "2016",
			"__prefix": "ns2"
		},
		{
			"_domIntlCd": "A",
			"_grossSales": "2811376910.00",
			"_mon": "11",
			"_monthName": "NOV",
			"_tranCnt": "6785896",
			"_yearMonth": "NOV 2016",
			"_yr": "2016",
			"__prefix": "ns2"
		},
		{
			"_domIntlCd": "D",
			"_grossSales": "0.00",
			"_mon": "11",
			"_monthName": "NOV",
			"_tranCnt": "0",
			"_yearMonth": "NOV 2016",
			"_yr": "2016",
			"__prefix": "ns2"
		},
		{
			"_domIntlCd": "I",
			"_grossSales": "0.00",
			"_mon": "11",
			"_monthName": "NOV",
			"_tranCnt": "0",
			"_yearMonth": "NOV 2016",
			"_yr": "2016",
			"__prefix": "ns2"
		},
		{
			"_domIntlCd": "A",
			"_grossSales": "2434734890.00",
			"_mon": "12",
			"_monthName": "DEC",
			"_tranCnt": "6048387",
			"_yearMonth": "DEC 2016",
			"_yr": "2016",
			"__prefix": "ns2"
		},
		{
			"_domIntlCd": "D",
			"_grossSales": "0.00",
			"_mon": "12",
			"_monthName": "DEC",
			"_tranCnt": "0",
			"_yearMonth": "DEC 2016",
			"_yr": "2016",
			"__prefix": "ns2"
		},
		{
			"_domIntlCd": "I",
			"_grossSales": "0.00",
			"_mon": "12",
			"_monthName": "DEC",
			"_tranCnt": "0",
			"_yearMonth": "DEC 2016",
			"_yr": "2016",
			"__prefix": "ns2"
		},
		{
			"_domIntlCd": "A",
			"_grossSales": "3514558903.00",
			"_mon": "1",
			"_monthName": "JAN",
			"_tranCnt": "8376244",
			"_yearMonth": "JAN 2017",
			"_yr": "2017",
			"__prefix": "ns2"
		},
		{
			"_domIntlCd": "D",
			"_grossSales": "0.00",
			"_mon": "1",
			"_monthName": "JAN",
			"_tranCnt": "0",
			"_yearMonth": "JAN 2017",
			"_yr": "2017",
			"__prefix": "ns2"
		},
		{
			"_domIntlCd": "I",
			"_grossSales": "0.00",
			"_mon": "1",
			"_monthName": "JAN",
			"_tranCnt": "0",
			"_yearMonth": "JAN 2017",
			"_yr": "2017",
			"__prefix": "ns2"
		},
		{
			"_domIntlCd": "A",
			"_grossSales": "3379665312.00",
			"_mon": "2",
			"_monthName": "FEB",
			"_tranCnt": "7739996",
			"_yearMonth": "FEB 2017",
			"_yr": "2017",
			"__prefix": "ns2"
		},
		{
			"_domIntlCd": "D",
			"_grossSales": "0.00",
			"_mon": "2",
			"_monthName": "FEB",
			"_tranCnt": "0",
			"_yearMonth": "FEB 2017",
			"_yr": "2017",
			"__prefix": "ns2"
		},
		{
			"_domIntlCd": "I",
			"_grossSales": "0.00",
			"_mon": "2",
			"_monthName": "FEB",
			"_tranCnt": "0",
			"_yearMonth": "FEB 2017",
			"_yr": "2017",
			"__prefix": "ns2"
		},
		{
			"_domIntlCd": "A",
			"_grossSales": "3911441347.00",
			"_mon": "3",
			"_monthName": "MAR",
			"_tranCnt": "8922987",
			"_yearMonth": "MAR 2017",
			"_yr": "2017",
			"__prefix": "ns2"
		},
		{
			"_domIntlCd": "D",
			"_grossSales": "0.00",
			"_mon": "3",
			"_monthName": "MAR",
			"_tranCnt": "0",
			"_yearMonth": "MAR 2017",
			"_yr": "2017",
			"__prefix": "ns2"
		},
		{
			"_domIntlCd": "I",
			"_grossSales": "0.00",
			"_mon": "3",
			"_monthName": "MAR",
			"_tranCnt": "0",
			"_yearMonth": "MAR 2017",
			"_yr": "2017",
			"__prefix": "ns2"
		},
		{
			"_domIntlCd": "A",
			"_grossSales": "3437583061.00",
			"_mon": "4",
			"_monthName": "APR",
			"_tranCnt": "7671179",
			"_yearMonth": "APR 2017",
			"_yr": "2017",
			"__prefix": "ns2"
		},
		{
			"_domIntlCd": "D",
			"_grossSales": "0.00",
			"_mon": "4",
			"_monthName": "APR",
			"_tranCnt": "0",
			"_yearMonth": "APR 2017",
			"_yr": "2017",
			"__prefix": "ns2"
		},
		{
			"_domIntlCd": "I",
			"_grossSales": "0.00",
			"_mon": "4",
			"_monthName": "APR",
			"_tranCnt": "0",
			"_yearMonth": "APR 2017",
			"_yr": "2017",
			"__prefix": "ns2"
		},
		{
			"_domIntlCd": "A",
			"_grossSales": "3599856320.00",
			"_mon": "5",
			"_monthName": "MAY",
			"_tranCnt": "8051607",
			"_yearMonth": "MAY 2017",
			"_yr": "2017",
			"__prefix": "ns2"
		},
		{
			"_domIntlCd": "D",
			"_grossSales": "0.00",
			"_mon": "5",
			"_monthName": "MAY",
			"_tranCnt": "0",
			"_yearMonth": "MAY 2017",
			"_yr": "2017",
			"__prefix": "ns2"
		},
		{
			"_domIntlCd": "I",
			"_grossSales": "0.00",
			"_mon": "5",
			"_monthName": "MAY",
			"_tranCnt": "0",
			"_yearMonth": "MAY 2017",
			"_yr": "2017",
			"__prefix": "ns2"
		},
		{
			"_domIntlCd": "A",
			"_grossSales": "1635523726.00",
			"_mon": "6",
			"_monthName": "JUN",
			"_tranCnt": "3829260",
			"_yearMonth": "JUN 2017",
			"_yr": "2017",
			"__prefix": "ns2"
		},
		{
			"_domIntlCd": "D",
			"_grossSales": "0.00",
			"_mon": "6",
			"_monthName": "JUN",
			"_tranCnt": "0",
			"_yearMonth": "JUN 2017",
			"_yr": "2017",
			"__prefix": "ns2"
		},
		{
			"_domIntlCd": "I",
			"_grossSales": "0.00",
			"_mon": "6",
			"_monthName": "JUN",
			"_tranCnt": "0",
			"_yearMonth": "JUN 2017",
			"_yr": "2017",
			"__prefix": "ns2"
		},
		{
			"_domIntlCd": "A",
			"_grossSales": "1511036715.00",
			"_mon": "7",
			"_monthName": "JUL",
			"_tranCnt": "3718408",
			"_yearMonth": "JUL 2017",
			"_yr": "2017",
			"__prefix": "ns2"
		},
		{
			"_domIntlCd": "D",
			"_grossSales": "0.00",
			"_mon": "7",
			"_monthName": "JUL",
			"_tranCnt": "0",
			"_yearMonth": "JUL 2017",
			"_yr": "2017",
			"__prefix": "ns2"
		},
		{
			"_domIntlCd": "I",
			"_grossSales": "0.00",
			"_mon": "7",
			"_monthName": "JUL",
			"_tranCnt": "0",
			"_yearMonth": "JUL 2017",
			"_yr": "2017",
			"__prefix": "ns2"
		},
		{
			"_domIntlCd": "A",
			"_mon": "8",
			"_monthName": "AUG",
			"_yearMonth": "AUG 2017",
			"_yr": "2017",
			"__prefix": "ns2"
		},
		{
			"_domIntlCd": "D",
			"_mon": "8",
			"_monthName": "AUG",
			"_yearMonth": "AUG 2017",
			"_yr": "2017",
			"__prefix": "ns2"
		},
		{
			"_domIntlCd": "I",
			"_mon": "8",
			"_monthName": "AUG",
			"_yearMonth": "AUG 2017",
			"_yr": "2017",
			"__prefix": "ns2"
		},
		{
			"_domIntlCd": "A",
			"_mon": "9",
			"_monthName": "SEP",
			"_yearMonth": "SEP 2017",
			"_yr": "2017",
			"__prefix": "ns2"
		},
		{
			"_domIntlCd": "D",
			"_mon": "9",
			"_monthName": "SEP",
			"_yearMonth": "SEP 2017",
			"_yr": "2017",
			"__prefix": "ns2"
		},
		{
			"_domIntlCd": "I",
			"_mon": "9",
			"_monthName": "SEP",
			"_yearMonth": "SEP 2017",
			"_yr": "2017",
			"__prefix": "ns2"
		},
		{
			"_domIntlCd": "A",
			"_mon": "10",
			"_monthName": "OCT",
			"_yearMonth": "OCT 2017",
			"_yr": "2017",
			"__prefix": "ns2"
		},
		{
			"_domIntlCd": "D",
			"_mon": "10",
			"_monthName": "OCT",
			"_yearMonth": "OCT 2017",
			"_yr": "2017",
			"__prefix": "ns2"
		},
		{
			"_domIntlCd": "I",
			"_mon": "10",
			"_monthName": "OCT",
			"_yearMonth": "OCT 2017",
			"_yr": "2017",
			"__prefix": "ns2"
		},
		{
			"_domIntlCd": "A",
			"_mon": "11",
			"_monthName": "NOV",
			"_yearMonth": "NOV 2017",
			"_yr": "2017",
			"__prefix": "ns2"
		},
		{
			"_domIntlCd": "D",
			"_mon": "11",
			"_monthName": "NOV",
			"_yearMonth": "NOV 2017",
			"_yr": "2017",
			"__prefix": "ns2"
		},
		{
			"_domIntlCd": "I",
			"_mon": "11",
			"_monthName": "NOV",
			"_yearMonth": "NOV 2017",
			"_yr": "2017",
			"__prefix": "ns2"
		},
		{
			"_domIntlCd": "A",
			"_mon": "12",
			"_monthName": "DEC",
			"_yearMonth": "DEC 2017",
			"_yr": "2017",
			"__prefix": "ns2"
		},
		{
			"_domIntlCd": "D",
			"_mon": "12",
			"_monthName": "DEC",
			"_yearMonth": "DEC 2017",
			"_yr": "2017",
			"__prefix": "ns2"
		},
		{
			"_domIntlCd": "I",
			"_mon": "12",
			"_monthName": "DEC",
			"_yearMonth": "DEC 2017",
			"_yr": "2017",
			"__prefix": "ns2"
		}
	],
	"totalRecordCount": 144
};

const carrierTicketSalesOverview = {
	"results": {
		"_arcAvgSalesAmountVar": "-11.45",
		"_arcCyAvgSalesAmount": "579.90",
		"_arctotalSales": "91563515749.77",
		"_arctransactionCount": "170143300",
		"_arcPyAvgSalesAmount": "654.87",
		"_arctotalSales_prior": "70681545916.84",
		"_arctransactionCount_prior": "118606438",
		"_arcTotalSalesVar": "29.54",
		"_arcTransactionCountVar": "43.45",
		"_asOfDate": "2017-07-31-04:00",
		"_avgSalesAmountVar": "-16.79",
		"_carrNbr": "1",
		"_carrNm": "AMERICAN AIRLINES",
		"_commissionPaidVar": "106.99",
		"_cyAvgSalesAmount": "460.33",
		"_cyCommissionPaid": "187947346.72",
		"_cyEstCashSavings": "50209737.61",
		"_totalSales": "23826448520.33",
		"_transactionCount": "54571104",
		"_estCashSavingsVar": "137.59",
		"_pyAvgSalesAmount": "553.24",
		"_pyCommissionPaid": "90798975.58",
		"_pyEstCashSavings": "21132815.21",
		"_totalSales_prior": "11465561553.32",
		"_transactionCount_prior": "22434476",
		"_totalSalesVar": "107.81",
		"_transactionCountVar": "143.25",
		"__prefix": "ns2"
	},
	"totalRecordCount": 1
};

const creditCardSalesByBrand = {
	"results": [
		{
			creditCardCompanyAbbr: "Visa",
			"_creditCardCompanyDesc": "Visa International",
			"_pymtBrandCd": "VI",
			tranAmt: 8587294940.59,
		},
		{
			creditCardCompanyAbbr: "Amex",
			"_creditCardCompanyDesc": "Other",
			"_pymtBrandCd": "AX",
			tranAmt: 7071944095.47,
		},
		{
			creditCardCompanyAbbr: "Mastercard",
			"_creditCardCompanyDesc": "MasterCard",
			"_pymtBrandCd": "CA",
			tranAmt: 4893639513.28,
		},
		{
			creditCardCompanyAbbr: "UATP",
			"_creditCardCompanyDesc": "Universal Air Travel Plan/Air Plus Card",
			"_pymtBrandCd": "TP",
			tranAmt: 476724818.34,
		},
		{
			creditCardCompanyAbbr: "Discover",
			"_creditCardCompanyDesc": "Discover",
			"_pymtBrandCd": "DS",
			tranAmt: 280660979.38,
		},
		{
			creditCardCompanyAbbr: "Other",
			"_creditCardCompanyDesc": "Other",
			"_pymtBrandCd": "OT",
			tranAmt: 4331486.50,
		}
	],
	"totalRecords": 6
}

const transactions = {
	"results": [
		{
			transactions: 1098470718.37,
			transactions_diff: 21342,
			transactions_percent_diff: 45,
			transactions_all: 352545763,
			transactions_all_diff: 345345,
			transactions_all_percent_diff: 10,

			sales: 1098470718.37,
			sales_diff: 21342,
			sales_percent_diff: 45,
			sales_all: 352545763,
			sales_all_diff: 345345,
			sales_all_percent_diff: 10,

			average_sales: 1098470718.37,
			average_sales_diff: 21342,
			average_sales_percent_diff: 45,
			average_sales_all: 352545763,
			average_sales_all_diff: 345345,
			average_sales_all_percent_diff: 10,

			commission_amount: 1098470718.37,
			commission_amount_diff: 21342,
			commission_amount_percent_diff: 45,

			cash_savings: 1098470718.37,
			cash_savings_diff: 21342,
			cash_savings_percent_diff: 45,

			ticketMarketShare: 60,

			summary: "summary"
		}
	],
	"totalRecordCount": 1
}

const topAgencies = {
	"results": [
		{
			agency: "EXPEDIA.COM TRAVEL (50543474)", agencyNbr: "50543474", agencyNm: "EXPEDIA.COM TRAVEL", totalSales: 385519713.42, transactionCount: 13605351, totalSales_prior: 45.32, transactionCount_prior: 45.6, topAgencies: 1, totalSalesVar: 217.50, transactionCountVar: 262.81
		},
		{
			agency: "AMEXGBT (03537380)", agencyNbr: "03537380", agencyNm: "AMEXGBT", totalSales: 2135814182.02, transactionCount: 4078491, totalSales_prior: 34.78, transactionCount_prior: 23.9, topAgencies: 2, totalSalesVar: 93.74, transactionCountVar: 115.87
		},
		{
			agency: "BCD TRAVEL (11617152)", agencyNbr: "11617152", agencyNm: "BCD TRAVEL", totalSales: 1557216253.58, transactionCount: 2888627, totalSales_prior: 14.02, transactionCount_prior: 87.9, topAgencies: 3, totalSalesVar: 96.10, transactionCountVar: 124.58
		},
		{
			agency: "TRAVELSCAPE LLC (29546904)", agencyNbr: "29546904", agencyNm: "TRAVELSCAPE LLC", totalSales: 1049819329.63, transactionCount: 2417541, totalSales_prior: 78.01, transactionCount_prior: 12.8, topAgencies: 4, totalSalesVar: 141.26, transactionCountVar: 157.04
		},
		{
			agency: "CARLSON WAGONLIT TRAVEL (24746735)", agencyNbr: "24746735", agencyNm: "CARLSON WAGONLIT TRAVEL", totalSales: 1253845929.94, transactionCount: 2392182, totalSales_prior: 54.47, transactionCount_prior: 76.8, topAgencies: 5, totalSalesVar: 91.46, transactionCountVar: 117.09
		},
		{
			agency: "PRICELINE.COM (07508546)", agencyNbr: "07508546", agencyNm: "PRICELINE.COM", totalSales: 662209716.59, transactionCount: 2188301, totalSales_prior: 78.01, transactionCount_prior: 54, topAgencies: 6, totalSalesVar: 91.22, transactionCountVar: 115.21
		},
		{
			agency: "TRAVELONG (33895934)", agencyNbr: "33895934", agencyNm: "TRAVELONG", totalSales: 680429113.94, transactionCount: 2105296, totalSales_prior: 90.76, transactionCount_prior: 79.7, topAgencies: 7, totalSalesVar: 110.78, transactionCountVar: 157.68
		},
		{
			agency: "LOYALTY TRAVEL (13527231)", agencyNbr: "13527231", agencyNm: "LOYALTY TRAVEL", totalSales: 615795744.92, transactionCount: 1591904, totalSales_prior: 98.82, transactionCount_prior: 7, topAgencies: 8, totalSalesVar: 139.94, transactionCountVar: 178.88
		},
		{
			agency: "ORBITZ WORLDWIDE LLC (14538031)", agencyNbr: "14538031", agencyNm: "ORBITZ WORLDWIDE LLC", totalSales: 582583401.80, transactionCount: 1518011, totalSales_prior: 51.48, transactionCount_prior: 43, topAgencies: 9, totalSalesVar: -28.00, transactionCountVar: -18.07
		},
		{
			agency: "CWTSATOTRAVEL (49726316)", agencyNbr: "49726316", agencyNm: "CWTSATOTRAVEL", totalSales: 673109987.52, transactionCount: 1485800, totalSales_prior: 29.70, transactionCount_prior: 25.8, topAgencies: 10, totalSalesVar: 64.44, transactionCountVar: 77.36
		}
	]
}

const transactions_total = {
	results: [
		{ label: "Sales", transaction: 5400, marketShare: 500 },
		{ label: "Exchanges", transaction: 2700, marketShare: 300 },
		{ label: "EMD", transaction: 7300, marketShare: 100 },
		{ label: "Refunds", transaction: 5700, marketShare: 900 }
	]
}

const transactions_domestic = {
	results: [
		{ label: "Sales", transaction: 700, marketShare: 400 },
		{ label: "Exchanges", transaction: 500, marketShare: 900 },
		{ label: "EMD", transaction: 300, marketShare: 500 },
		{ label: "Refunds", transaction: 700, marketShare: 300 }
	]
}

const transactions_international = {
	results: [
		{ label: "Sales", transaction: 900, marketShare: 60 },
		{ label: "Exchanges", transaction: 700, marketShare: 100 },
		{ label: "EMD", transaction: 600, marketShare: 50 },
		{ label: "Refunds", transaction: 400, marketShare: 30 }
	]
}

const ticket_trend = {
	results: [
		{ month: "Jan", sValue2022: 100, sValue2023: 500, sValue2024: 700, tValue2022: 100, tValue2023: 500, tValue2024: 700 },
		{ month: "Feb", sValue2022: 200, sValue2023: 300, sValue2024: 500, tValue2022: 200, tValue2023: 300, tValue2024: 100 },
		{ month: "Mar", sValue2022: 150, sValue2023: 150, sValue2024: 740, tValue2022: 150, tValue2023: 150, tValue2024: 740 },
		{ month: "Apr", sValue2022: 100, sValue2023: 500, sValue2024: 700, tValue2022: 100, tValue2023: 500, tValue2024: 700 },
		{ month: "May", sValue2022: 200, sValue2023: 300, sValue2024: 100, tValue2022: 200, tValue2023: 300, tValue2024: 100 },
		{ month: "Jun", sValue2022: 450, sValue2023: 350, sValue2024: 740, tValue2022: 650, tValue2023: 950, tValue2024: 740 },
		{ month: "Jul", sValue2022: 200, sValue2023: 500, sValue2024: 300, tValue2022: 100, tValue2023: 500, tValue2024: 700 },
		{ month: "Aug", sValue2022: 200, sValue2023: 300, sValue2024: 100, tValue2022: 200, tValue2023: 300, tValue2024: 100 },
		{ month: "Sep", sValue2022: 850, sValue2023: 150, sValue2024: 740, tValue2022: 150, tValue2023: 150, tValue2024: 740 },
		{ month: "Oct", sValue2022: 900, sValue2023: 600, sValue2024: 700, tValue2022: 100, tValue2023: 500, tValue2024: 700 },
		{ month: "Nov", sValue2022: 200, sValue2023: 300, sValue2024: 100, tValue2022: 200, tValue2023: 300, tValue2024: 100 },
		{ month: "Dec", sValue2022: 250, sValue2023: 150, sValue2024: 740, tValue2022: 150, tValue2023: 150, tValue2024: 740 }
	]
}

const gds = {
	results: [
		{ label: "Fairlogic", transaction: 4100, distribution: 500 },
		{ label: "Sabre", transaction: 5800, distribution: 340 },
		{ label: "Worldspan", transaction: 1300, distribution: 170 },
		{ label: "Amadeus", transaction: 4700, distribution: 910 },
		{ label: "Apollo", transaction: 2300, distribution: 790 },
		{ label: "galilieo", transaction: 6800, distribution: 250 },
		{ label: "Fairlogic", transaction: 4100, distribution: 530 },
		{ label: "Sabre", transaction: 5800, distribution: 390 },
		{ label: "Worldspan", transaction: 1300, distribution: 700 },
		{ label: "Amadeus", transaction: 4700, distribution: 980 }
	]
}

const credit = {
	results: [
		{ label: "Discover", transaction: 500, distribution: 300 },
		{ label: "Visa", transaction: 800, distribution: 200 },
		{ label: "Mastercard", transaction: 300, distribution: 1100 },
		{ label: "Amex", transaction: 300, distribution: 700 },
		{ label: "UATP", transaction: 900, distribution: 300 }
	]
}

const creditCash = {
	results: [
		{ type: "Credit", value: 1545200 },
		{ type: "Cash", value: 503450 }
	]
}

const ondDemand = {
	results: [
		{ type: "IAD-ORD", value: 1023400 },
		{ type: "ORD-IAH", value: 102340 },
		{ type: "DCA-ORD", value: 500560 },
		{ type: "IAH-IAD", value: 30400 },
		{ type: "SJO-ATL", value: 125600 },
		{ type: "ATL-DCA", value: 64540 }
	]
};

const airportDemand = {
	results: [
		{ type: "IAD", value: 100 },
		{ type: "ORD", value: 1500 },
		{ type: "DCA", value: 5200 },
		{ type: "IAH", value: 3900 },
		{ type: "SJO", value: 2200 },
		{ type: "ATL", value: 600 }
	]
};

const airlineDemand = {
	results: [
		{ type: "AA", value: 1700 },
		{ type: "DL", value: 8700 },
		{ type: "EY", value: 2200 },
		{ type: "IA", value: 4100 },
		{ type: "CO", value: 6200 },
		{ type: "JB", value: 2600 },
		{ type: "X1", value: 2700 },
		{ type: "FT", value: 1200 },
		{ type: "JI", value: 3150 },
		{ type: "YM", value: 2200 }
	]
};

export const cdbdata = {
	carrierSaleTrend,
	carrierTicketSalesOverview,
	creditCardSalesByBrand,
	transactions,
	commission_and_cash_savings: transactions,
	ticketMarketShare: transactions,
	topAgencies,
	creditCash,
	transactions_total,
	transactions_domestic,
	transactions_international,
	ticket_trend,
	ndc_trend: ticket_trend,
	edifact_trend: ticket_trend,
	gds,
	credit,
	ondDemand,
	airportDemand,
	airlineDemand
}