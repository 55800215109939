import axios from "axios";
import { convertFileBase64 } from "../../../components/utils";

declare const window: any;

export const setFeedback = (body: any) => {
    return async (dispatch: any) => {
        // let payload = { ...body, message: body.message.replace(/(\r\n|\n|\r)/gm, '||n') }
        dispatch({ type: "LOADING", data: "Sending your feedback..." });
        let payload = { ...body, message: body.message }
        if (payload.attachment) {
            const base64 = await convertFileBase64(body.attachment)
            payload = { ...payload, attachment: { file: payload.attachment.name, content: base64 } }
        }

        return axios.put(window.ENV.customerFeedback, payload, {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem("bearer"),
                'Content-Type': 'application/json',
            }
        }).then((response) => {
            dispatch({ type: "LOADING", data: "" });
            dispatch({ type: "STATUS", data: { type: "SUCCESS", title: "Success", message: `Thank you for your feedback` } });
            return response
        }).catch(err => {
            console.log("ERROR:", err);
            dispatch({ type: "STATUS", data: { type: "DANGER", title: "Error", message: `The server encountered an error and could not complete your request. (${err.message})` } });
        });
    };
};

export const uploadFile = (file: any, onProgress: (percentage: number) => void) => {
    return async (dispatch: any) => {
        axios.put(window.ENV.customerFeedback, file, {
            onUploadProgress: e => {
                if (e.lengthComputable) {
                    const percentage = (e.loaded / (e.total || 1)) * 100;
                    onProgress(Math.round(percentage));
                }
            }
        }).then((res) => void (res))
            .catch(err => {
                if (err.response?.status !== 401) {
                    dispatch({ type: "STATUS", data: { type: "DANGER", title: "Error", message: "Error uploading file. Try again." } });
                }
            });
    };
}