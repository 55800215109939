import React from "react";
import { Redirect } from "react-router-dom";
// import OktaSignInWidget from "./OktaSignInWidget";
// import { useOktaAuth } from "@okta/okta-react";
import { useDispatch } from "react-redux";
import { useAuth0, LocalStorageCache } from "@auth0/auth0-react";
import { clearLocalStorage } from "../components/utils";

declare const window: any;

const Login = () => {
  const dispatch = useDispatch();
  const { isAuthenticated, logout, getIdTokenClaims, loginWithRedirect, error, user } = useAuth0();
  // const { oktaAuth, authState } = useOktaAuth();

  

  // const onSuccess = async (tokens) => {
  //   localStorage.setItem("isTimeoutShowed", "false");
  //   console.log("LOGIN SUCCESS...", tokens);
  //   dispatch({ type: "LOGIN", data: tokens });

  //   localStorage.setItem("bearer", tokens.accessToken.accessToken);
  //   localStorage.setItem("refresh", tokens.refreshToken.refreshToken);
  //   localStorage.setItem("expiresAt", tokens.accessToken.expiresAt);
  //   setUser(tokens.idToken.claims.name);

  //   loginWithRedirect(tokens);

  //   oktaAuth.handleLoginRedirect(tokens);
  //   setDownload();
  // };

  if (error) {
    console.log("error logging in", error);
    clearLocalStorage();
    logout({
      logoutParams: {
        returnTo: window.location.origin,
      },
    });
  }

  if (isAuthenticated) {
    setTimeout(async () => {
      try {
        var tokens: any = await getIdTokenClaims();
        if (tokens != null) {
          localStorage.setItem("isTimeoutShowed", "false");
          dispatch({ type: "LOGIN", data: tokens });

          //Refresh Token:
          const refresh_token = new LocalStorageCache();
          const key = refresh_token.allKeys().find((key) => key.includes("auth0spajs") && key.includes(window.ENV.oktaConfig.domain));
          const refresh_token_value: any = refresh_token.get(key || "");
          const finalRefreshToken = refresh_token_value?.body?.refresh_token;
          localStorage.setItem("bearer", tokens.__raw);
          localStorage.setItem("refresh_token", finalRefreshToken);
          localStorage.setItem("expiresAt", tokens.exp);
          loginWithRedirect(tokens);
        }
      } catch (e) {
        logout({
          logoutParams: {
            returnTo: window.location.origin,
          },
        });
      }
    }, 500);
  } else {
    loginWithRedirect();
  }

  // const onError = (err, tokens) => {
  //   console.log("error logging in", err);
  //   oktaAuth.handleLoginRedirect(tokens);
  // };

  if (!user) {
    return null;
  }

  return isAuthenticated ? (
    <Redirect to={{ pathname: "/" }} />
  ) : (
    <Redirect to={{ pathname: "/logout" }} />
    // <OktaSignInWidget config={config} onSuccess={onSuccess} onError={onError} />
  );
};
export default Login;
