export const reportIpRef = (state = {}, action: any) => {
    switch (action.type) {
        case "REPORT_IP":
            return action.data;
        default:
            return state;
    }
};

export const lockBucketRef = (state = false, action: any) => {
    switch (action.type) {
        case "LOCK_BUCKET":
            return true;
        case "UNLOCK_BUCKET":
            return false;
        default:
            return state;
    }
}

export const lookupBucketRef = (state = [], action: any) => {
    switch (action.type) {
        case "ADD_TO_BUCKET":
            const count = state.filter((item: any) => item?.code === action.data.code)?.length || 0;
            return count === 0 ? [...state, action.data] : state;
        case "ADD_ALL_TO_BUCKET":
            const tdata: any = [...state];
            action.data.forEach((d:any) => {
                if(state.filter((item: any) => item?.code === d.code)?.length === 0) {
                    tdata.push(d);
                }
            })
            return [...tdata];
        case "REMOVE_BUCKET_ITEM":
            return [...state.filter((item: any) => item?.code !== action.data.code)];
        case "CLEAR_BUCKET":
            return [];
        default:
            return state;
    }
}