import { useState, useEffect, useRef } from 'react'
import { useSelector } from 'react-redux';
import { Accordion, Button, Badge, Row } from 'react-bootstrap'
import './releaseNote.scss'

const ReleaseNote = () => {

    const eleRef = useRef() as React.MutableRefObject<HTMLInputElement>;

    const [show, setShow] = useState(false);
    const [messages, setMessages]: any = useState();
    const releaseRef: any = useSelector((state: any) => state.messagesRef);

    // if no reference to message check
    if (!localStorage.getItem("REF:releaseChecked")) {
        localStorage.setItem('REF:releaseChecked', JSON.stringify({ "isChecked": false, "checkDate": null }))
    }

    const releaseChecked = JSON.parse(localStorage.getItem('REF:releaseChecked')!)

    useEffect(() => {
        if (releaseRef['release_Note']?.data.length) {
            setMessages(releaseRef['release_Note']?.data.filter((obj: any) => new Date(obj.active.endDate[0]) >= new Date(new Date().setHours(0, 0, 0, 0)) && new Date(obj.active.startDate[0]) <= new Date(new Date().setHours(0, 0, 0, 0))))
        }
    }, [releaseRef])

    useEffect(() => {
        if (messages && messages.length > 0) {
            const lastModifiedDate = new Date(releaseRef['release_Note'].lastModified).toString()
            //sorting by closest future date            
            const sortMsgLatest = messages.sort((a: any, b: any) => Math.abs(Date.now() - new Date(a.active.startDate[0]).getTime()) - Math.abs(Date.now() - new Date(b.active.startDate[0]).getTime()))
            // If checked date is older than newly updated date
            if (new Date(releaseChecked?.checkDate) < new Date(lastModifiedDate)) {
                localStorage.setItem('REF:releaseChecked', JSON.stringify({ "isChecked": false, "checkDate": null }))
                eleRef.current.style.opacity = "1";
            }
            setMessages(sortMsgLatest);
        }
    }, [releaseRef, releaseChecked, messages])

    const handleBadgeClick = () => {
        setShow(!show)
        if (releaseChecked?.isChecked === false) {
            localStorage.setItem('REF:releaseChecked', JSON.stringify({ "isChecked": true, "checkDate": new Date() }))
            eleRef.current.style.opacity = "0";
        }
    }

    if (!messages?.length) return null

    return (
        <Row className='accordionPage'>
            {show && <div aria-label='close release note' onClick={() => setShow(false)} className="clearIcon"><i className="fds-glyphs-clear"></i></div>}
            {show && <div className='accordionParent'>
                <Accordion defaultActiveKey="0">
                    {messages.map((message: any, i: number) => {
                        return (
                            <Accordion.Item eventKey={`${i}`} key={i}>
                                <Accordion.Header>{message.title}</Accordion.Header>
                                <Accordion.Body>
                                    <div className='releaseTitle ps-3'>{message.active?.startDate[0]}</div>
                                    <ul className='ps-3'>
                                        {message.messages.map((msg: any, i: number) => {
                                            return (
                                                <li className='pt-3' key={i}>
                                                    {msg}
                                                </li>
                                            )
                                        })}
                                    </ul>
                                </Accordion.Body>
                            </Accordion.Item>
                        )
                    })}
                </Accordion>
            </div>}
            <h6 ref={eleRef} onClick={() => handleBadgeClick()}>
                <Badge pill bg="primary" aria-label='New release note' className="py-2 px-3">NEW</Badge>
            </h6>
            <Button className="releaseIcon" aria-label="Click here to view release notes" onClick={() => handleBadgeClick()} variant='primary'>
                <i className="fds-glyphs-info"></i>
            </Button >
        </Row>

    )
}

export default ReleaseNote