import React, { useEffect } from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import * as FDS from '@arctravel/react-fds/lib';
import { useDispatch } from 'react-redux';
import { getOts, getOtsLength, isInternalAdmin } from '../../okta/ls';
import { capitalize } from '../utils';

import './Admin.scss';

declare const window: any;

export const Admin = (props: any) => {
    const dispatch = useDispatch();

    useEffect(() => {
        window.scrollTo(0, 0);
        dispatch({ type: "REPORT_IP", data: {} });
    }, [dispatch]);

    return (
        <FDS.FDSLevel mainTitle={"Welcome, " + (getOtsLength() > 0 ? capitalize(getOts()?.idToken?.claims?.name) : "")} subTitle={"View and manage Discovery Point access and content"} type="three" name="adminDashboardPage">
            <Container>
                <Row>
                    <Col xs={2}>

                    </Col>

                    <Col xs={isInternalAdmin() ? 4 : 8}>
                        <Card className="striped with-routing primary left">
                            <Link to="/admin/customerinfo">
                                <Card.Body>
                                    <Card.Title as="h5">Customer List</Card.Title>
                                    <Card.Text>
                                        View and administer customer information to add, delete and update user access criteria.</Card.Text>
                                </Card.Body>
                                <Card.Footer>
                                    <div className="d-flex justify-content-end align-items-center">
                                        <i className="arrow fds-glyphs-arrow-right3"></i>
                                    </div>
                                </Card.Footer>
                            </Link>
                        </Card>
                    </Col>
                    {isInternalAdmin() && <Col xs={4}>
                        <Card className="striped with-routing primary left">
                            <Link to="/admin/messages">
                                <Card.Body>
                                    <Card.Title as="h5">Important Messages</Card.Title>
                                    <Card.Text>
                                        View and manage the content of customer-facing communications that are accessed via the user interface.</Card.Text>
                                </Card.Body>
                                <Card.Footer>
                                    <div className="d-flex justify-content-end align-items-center">
                                        <i className="arrow fds-glyphs-arrow-right3"></i>
                                    </div>
                                </Card.Footer>
                            </Link>
                        </Card>
                    </Col>}

                    <Col xs={2}>

                    </Col>
                </Row>
            </Container>
            <div></div>
        </FDS.FDSLevel>
    );
}