import React, { useState, useEffect } from 'react'
import { Pagination } from 'react-bootstrap'

export const DynamicPagination = (props: any) => {
    const { itemPerPage, totalItems, onPageChange } = props;
    const pageNumber = [];
    const [currentPage, setCurrentPage] = useState(props.currentPage || 1);
    for (let i = 1; i <= Math.ceil(totalItems / itemPerPage); i++) {
        pageNumber.push(i)
    }
    useEffect(() => {
        setCurrentPage(props.currentPage);
    }, [props.currentPage]);
    return (
        <Pagination className={props.className || ""}>
            {pageNumber.map((number: number) => {
                return <Pagination.Item className={currentPage === number ? "active" : ""} key={number} onClick={() => {
                    setCurrentPage(number);
                    onPageChange(number);
                }}>{number}</Pagination.Item>
            })}
        </Pagination>
    )
}