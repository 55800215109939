import { combineReducers } from 'redux';

import { referenceDataRef, referenceLoadedRef, templatesLoadedRef } from './references';
import { savedAllTemplatesRef, savedTemplatesRef, rerunTemplateRef } from './templates';
import { statusRef, loaderRef, loginRef } from './status';
import { groupingsDataRef, groupingsOrgRef } from './groupings';
import { notificationsRef, notificationRef } from './notifications';
import { reportIpRef, lookupBucketRef, lockBucketRef } from './reportinput';
import { historyRef } from './history';
import { messagesRef } from "./messages";
import { customersRef } from "./customers";
// import { templatesFormRef } from "./templatesForm";

const reducers = combineReducers({
    referenceDataRef: referenceDataRef,
    referenceLoadedRef: referenceLoadedRef,
    templatesLoadedRef: templatesLoadedRef,
    savedAllTemplatesRef: savedAllTemplatesRef,
    savedTemplatesRef: savedTemplatesRef,
    groupingsDataRef: groupingsDataRef,
    groupingsOrgRef: groupingsOrgRef,
    notificationsRef: notificationsRef,
    notificationRef: notificationRef,
    rerunTemplateRef: rerunTemplateRef,
    reportIpRef: reportIpRef,
    statusRef: statusRef,
    loaderRef: loaderRef,
    loginRef: loginRef,
    historyRef: historyRef,
    messagesRef: messagesRef,
    lookupBucketRef: lookupBucketRef,
    lockBucketRef: lockBucketRef,
    customersRef: customersRef,
    // templatesFormRef: templatesFormRef
});

export default reducers;