import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Badge } from 'react-bootstrap';
import * as FDS from '@arctravel/react-fds/lib';
import { useSelector, useDispatch } from 'react-redux';
import { getOts, getOtsLength } from '../../okta/ls';
import { capitalize } from '../utils';

declare const window: any;

export const Profile = (props: any) => {
    const dispatch = useDispatch();
    const [customer, setCustomer]: any = useState({});
    const [user, setUser]: any[] = useState([]);
    const [agencySubscribers, setAgencySubscribers] = useState<[]>([])
    const referenceDataRef = useSelector((state: any) => state.referenceDataRef);
    const agencyRef = referenceDataRef[window.ENV.references.agency]
    const customersRef = useSelector((state: any) => state.customersRef);
    const roles: any = { "INTERNAL_ADMIN": "INTERNAL ADMIN", "EXTERNAL_ADMIN": "ADMIN", "INTERNAL_USER": "INTERNAL USER", "EXTERNAL_USER": "USER" }

    useEffect(() => {
        window.scrollTo(0, 0);
        dispatch({ type: "REPORT_IP", data: {} });
    }, [dispatch]);

    useEffect(() => {
        const customerRef = customersRef.customers.filter((c: any) => (c.org === (getOts()?.idToken?.orgName || getOts()?.accessToken?.claims?.org) || c.name === (getOts()?.idToken?.orgName || getOts()?.accessToken?.claims?.org)))[0] || {}
        setCustomer(customerRef);
        // Filter ref obj based on subscription code
        if (agencyRef && customerRef) {
            const code = "code";
            const parent = "parent"
            const agencySubscribed = new Set(customerRef.subscribers).add(customerRef.org)
            const checkedCode = new Set();
            const filteredArray: any = [];

            for (const obj of agencyRef) {
                const value = obj[code];
                const parentCode = obj[parent]
                if ((agencySubscribed.has(value) || agencySubscribed.has(parentCode)) && !checkedCode.has(value)) {
                    filteredArray.push(obj);
                    checkedCode.add(value);
                }
            }
            setAgencySubscribers(filteredArray)
        }
        setUser(getOts()?.idToken?.claims)
    }, [customersRef.customers, agencyRef]);

    return (
        <FDS.FDSLevel mainTitle={"My Profile"} subTitle={"Welcome, " + (getOtsLength() > 0 ? capitalize(getOts()?.idToken?.claims?.name) : "")} type="three" name="adminDetailsPage">
            <Container>
                {user?.last_updated_on ? <i>Profile last updated on {user?.last_updated_on}.</i> : null}
            </Container>
            <FDS.FDSLevel.Centered split={true}>
                <div className='customerDetailSummary'>
                    <div>
                        <label className='me-2'><b>Username:</b></label>{user?.email}
                    </div>
                    <div>
                        <label className='me-2'><b>Organization:</b></label>{customer?.name}
                    </div>
                    <div>
                        <label className='me-2'><b>Contract:</b></label>{customer?.contract_start} - {customer?.contract_end}
                    </div>
                    <div>
                        <label className='me-2'><b>Data Source:</b></label>{FDS.valueOrDash(customer?.data_source?.join(", "))}
                    </div>
                    <div>
                        <label className='me-2'><b>Subscription Info:</b></label>{FDS.valueOrDash(customer?.data_content?.join(", "))}
                    </div>
                    <div>
                        <label className='me-2'><b>Role:</b></label>{Array.isArray(user?.role) ? user?.role?.map((r: any) => roles[r]).join(", ") : roles[user?.role]}
                    </div>
                    <div>
                        <label className='me-2'><b>Email:</b></label>{user?.email}
                    </div>
                    {/* <div>
                        <label className='me-2'><b>Status:</b></label>Active
                    </div> */}
                </div>
                <div className='customerDetailSummary'>
                    {customer?.geography && Object.keys(customer?.geography)?.length + customer?.modules?.length > 0 ?
                        Object.keys(customer?.geography)?.sort((a: any, b: any) => a < b ? 1 : -1).map((k: string, ki: number) => {
                            return (
                                <div key={ki}>
                                    <Row style={{ textTransform: "capitalize", fontSize: "0.75rem", fontWeight: "bold" }}>
                                        <Col>
                                            <label><b>{k}</b></label>
                                        </Col>
                                        {customer.geography[k].length > 10 ?
                                            <Col className='right me-4'>
                                                <a href="/#" style={{ border: 'none' }} onClick={(ev: any) => {
                                                    ev.preventDefault();
                                                    ev.target.parentElement.parentElement.parentElement.classList.toggle("show")
                                                }}>
                                                    <small>View More</small>
                                                    <small className='hidable'>View Less</small>
                                                </a>
                                            </Col> : null
                                        }
                                    </Row>
                                    <div>
                                        {customer.geography[k].map((geog: string, gi: number) => {
                                            return <Badge key={gi} className={`p-1 ${gi >= 10 ? 'hidable' : ''}`} bg="primary">{geog}</Badge>
                                        })}
                                    </div>
                                </div>
                            )
                        }) :
                        <div>No geography information available.</div>
                    }
                    {
                        customer?.modules?.length > 0 ?
                            <Row className='pt-1'>
                                <Col>
                                    <div style={{ textTransform: "capitalize", fontSize: "0.75rem", fontWeight: "bold" }}>
                                        <label><b>Modules</b></label>
                                    </div>
                                    <Row style={{ textTransform: "capitalize", fontSize: "0.75rem", fontWeight: "bold" }}>
                                        {customer?.modules?.length > 10 ?
                                            <Col className='right me-4'>
                                                <a href="/#" style={{ border: 'none' }} onClick={(ev: any) => {
                                                    ev.preventDefault();
                                                    ev.target.parentElement.parentElement.parentElement.classList.toggle("show")
                                                }}>
                                                    <small>View More</small>
                                                    <small className='hidable'>View Less</small>
                                                </a>
                                            </Col> : null
                                        }
                                    </Row>
                                    <div>
                                        {customer?.modules?.map((mod: string, gi: number) => {
                                            return <Badge key={gi} className={`p-1 ${gi >= 10 ? 'hidable' : ''}`} bg="info">{mod}</Badge>
                                        })}
                                    </div>
                                </Col>
                            </Row> :
                            null
                    }
                    {agencySubscribers.length > 0 ?
                        <Row className='pt-3'>
                            <Col>
                                <Row style={{ textTransform: "capitalize", fontSize: "0.75rem", fontWeight: "bold" }}>
                                    <div style={{ textTransform: "capitalize", fontSize: "0.75rem", fontWeight: "bold", width: "24rem" }}>
                                        <label><b>Agencies Subscribed</b></label>
                                    </div>
                                    {agencySubscribers?.length > 10 ?
                                        <Col className='right me-4'>
                                            <a href="/#" style={{ border: 'none' }} onClick={(ev: any) => {
                                                ev.preventDefault();
                                                ev.target.parentElement.parentElement.parentElement.classList.toggle("show")
                                            }}>
                                                <small>View More</small>
                                                <small className='hidable'>View Less</small>
                                            </a>
                                        </Col> : null
                                    }
                                </Row>
                                <div>
                                    {agencySubscribers?.map((mod: any, gi: number) => {
                                        return <Badge key={mod.name} className={`p-1 ${gi >= 10 ? 'hidable' : ''}`} bg="secondary">{`${mod.name || mod.lname} (${mod.code})`}</Badge>
                                    })}
                                </div>
                            </Col>
                        </Row> : null
                    }
                </div>
            </FDS.FDSLevel.Centered>
        </FDS.FDSLevel>
    );
}