import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Const } from '../utils';
import { getSavedGroupingsAction, getMyAgency } from '../../store/actions';

const ViewAsHeader = () => {
    const dispatch = useDispatch();
    const statusRef = useRef<HTMLDivElement>(null);
    const [types] = useState([...Const.CUSTOMER_ENTITIES]);
    const customersRef: any = useSelector((state: any) => state.customersRef);
    const [viewAs, setViewAs]: any = useState({ ...Const.DEFAULT_VIEW_AS });
    const [customers, setCustomers]: any[] = useState([]);
    const [agencyId, setAgencyId] = useState("");

    const changeViewAs = (ev: React.ChangeEvent<HTMLSelectElement>) => {
        const prev = { ...viewAs };
        prev[ev.target.name] = ev.target.value;
        if (ev.target.name === "entity") {
            prev["org"] = Const.DEFAULT_ORG;
            prev["orgName"] = Const.DEFAULT_VIEW_AS.orgName
            dispatch({
                type: "SET_VIEW_AS",
                data: {
                    org: prev["org"],
                    orgName: prev["orgName"],
                    role: prev["role"]
                }
            });
        }
        dispatch({
            type: "SET_VIEW_AS", data: {
                [ev.target.name]: ev.target.value,
                orgName: ev.target.name === "org" ? ev.target.options[ev.target.selectedIndex].innerText : prev["orgName"],
                role: prev["role"]
            }
        });
        dispatch(getSavedGroupingsAction());

        setViewAs({ ...prev });

        const selectedCustomers = customers.filter((customer: any) => (customer.org === prev.org || customer.name === prev.org) && customer.entity === prev.entity);
        console.log("selectedCustomers", selectedCustomers)
        if (selectedCustomers.length > 0) {
            dispatch({ type: "GET_CUSTOMER", data: { ...selectedCustomers[0] } });
        }
    }

    useEffect(() => {
        setViewAs(customersRef.viewAs);
        const selectedCustomers = customersRef.customers.filter((customer: any) => (customer.org === customersRef.viewAs.org || customer.name === customersRef.viewAs.org) && customer.entity === customersRef.viewAs.entity);
        if (selectedCustomers.length > 0) {
            dispatch({ type: "GET_CUSTOMER", data: selectedCustomers[0] });
        }
    }, [dispatch, customersRef.customers, customersRef.viewAs, types]);

    useEffect(() => {
        const custs = customersRef.customers
            .filter((cust: any) => cust.entity?.toUpperCase() === viewAs?.entity?.toUpperCase())
            .filter((cust: any, index: number, self: any) =>
                index === self.findIndex((s: any) => (
                    s.org === cust.org
                ))
            ).sort((a: any, b: any) => a.org >= b.org ? 1 : -1);

        setCustomers([...custs]);

        const CUSTS = custs.filter((cust: any) => cust.org === viewAs?.org && cust.org?.length > 3);
        setAgencyId(CUSTS.length === 1 ? CUSTS[0].org : "");
    }, [customersRef.customers, viewAs, types]);

    useEffect(() => {
        if (statusRef?.current?.classList?.contains("init")) {
            statusRef?.current?.classList.remove("init");
        } else {
            dispatch(getMyAgency(agencyId));
        }
    }, [dispatch, agencyId]);

    return (
        <div id="viewAsWrap" className='pt-2 pb-2 ps-3 pe-3 init' ref={statusRef}>
            <span className='message ps-2'>
                <span className="txt">You are viewing the customer experience for:</span>
                <select name="entity" value={viewAs?.entity || ""} onChange={(ev: React.ChangeEvent<HTMLSelectElement>) => changeViewAs(ev)}>
                    <option value={types[0]}>Airlines</option>
                    <option value={types[1]}>Agency</option>
                    <option value={types[3]}>Others</option>
                </select>
                <select name="org" value={viewAs.org} onChange={(ev: React.ChangeEvent<HTMLSelectElement>) => changeViewAs(ev)}>
                    {
                        customers.map((cust: any, ci: number) => {
                            return (
                                <option key={cust.name + ci} value={cust.org}>
                                    {cust.name}
                                </option>
                            )
                        })
                    }
                </select>
                <select name="role" value={viewAs?.role} onChange={(ev: React.ChangeEvent<HTMLSelectElement>) => changeViewAs(ev)}>
                    {
                        viewAs?.org === Const.ARC_ROLE ?
                            <>
                                <option value={Const.INTERNAL_ADMIN}>Admin</option>
                                <option value={Const.INTERNAL_USER}>User</option>
                            </> :
                            <>
                                <option value={Const.EXTERNAL_ADMIN}>Admin</option>
                                <option value={Const.EXTERNAL_USER}>User</option>
                            </>
                    }
                </select>
            </span>

            <a href="/#" className='reset'
                onClick={(ev: any) => {
                    ev.preventDefault();
                    setViewAs({ ...Const.DEFAULT_VIEW_AS });
                    dispatch({ type: "SET_VIEW_AS", data: { ...Const.DEFAULT_VIEW_AS } });
                }}
            >Reset to Default</a>
        </div>
    )
}

export default ViewAsHeader