import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { Container, Row, Col, Alert, Button, Form, Dropdown, Card, Spinner, Table } from 'react-bootstrap';
import DropdownTreeSelect from 'react-dropdown-tree-select'
import 'react-dropdown-tree-select/dist/styles.css';
import * as FDS from '@arctravel/react-fds/lib';
import { useSelector, useDispatch } from 'react-redux';
import { toTree, refreshActivity, Const } from '../utils';
import { getOts, isInternalAdminOrUser } from '../../okta/ls';
import { setSavedGroupingsAction, deleteSavedGroupingsAction } from '../../store/actions';
import Feedback from '../modals/Feedback';
import { getSavedGroupingsAction } from '../../store/actions';


import './Groupings.scss'

declare const window: any;

/*
    Group Shared Values
    0: Private
    1: System
    2: Shared with ORG only
    2 as A2: Shared with ARC only in UI
*/

type GroupProps = {
    Id: string,
    org: string,
    name: string,
    owner_id: string,
    shared: "0" | "1" | "2",
    priority: string,
    type: string,
    values: string[],
    age: number,
    email?: string
}

// const priorityRef: any = ["Never", "Primary", "Secondary"]

export const Groupings = () => {

    const types: any = {
        "airport": "Airport Grouping",
        "airline": "Airlines Grouping",
        "region": "Region Grouping",
        "apap": "Market Grouping",
        "agency": "Agency Grouping"
    }

    const params: any = useParams();
    const dispatch = useDispatch();
    const historyNav = useHistory();
    const leftWrapRef = useRef();

    const [type, setType] = useState(window.ENV.references[params.id]);
    const referenceDataRef = useSelector((state: any) => state.referenceDataRef);
    const lookupBucketRef = useSelector((state: any) => state.lookupBucketRef);
    const lockBucketRef = useSelector((state: any) => state.lockBucketRef);
    const referenceLoadedRef = useSelector((state: any) => state.referenceLoadedRef);
    const [references, setReferences]: any = useState({});
    const groupingsDataRef = useSelector((state: any) => state.groupingsDataRef);
    const groupingsOrgRef = useSelector((state: any) => state.groupingsOrgRef);
    const customersRef: any = useSelector((state: any) => state.customersRef);

    const [groups, setGroups] = useState<GroupProps[]>([]);
    const [editableGroup, setEditableGroup]: any = useState({});
    const [viewAs, setViewAs] = useState(customersRef.viewAs);

    // hook to load reference data
    useEffect(() => {
        dispatch({ type: "REPORT_IP", data: {} });
        refreshActivity();
        if (referenceLoadedRef.status) {
            const fields = [{
                labelKey: "description",
                codeKey: "code",
                url: window.ENV.references.agency,
            }];

            fields.forEach((field: any) => {
                setReferences((prev: any) => {
                    prev[field.url] = referenceDataRef[field.url] && field.type === "tree" ? toTree(field.codeKey, field.labelKey, referenceDataRef[field.url]) : referenceDataRef[field.url];
                    return prev;
                });
            });
        }

        const fields = [{
            labelKey: "description",
            codeKey: "code",
            url: window.ENV.references.airline,
        }, {
            labelKey: "description",
            codeKey: "code",
            url: window.ENV.references.airport,
        }, {
            type: "tree",
            labelKey: "name",
            codeKey: "code",
            url: window.ENV.references.region,
        }, {
            labelKey: "description",
            codeKey: "code",
            url: window.ENV.references.city,
        }];

        fields.forEach((field: any) => {
            setReferences((prev: any) => {
                prev[field.url] = referenceDataRef[field.url] && field.type === "tree" ? toTree(field.codeKey, field.labelKey, referenceDataRef[field.url]) : referenceDataRef[field.url];
                return prev;
            });
        });
    }, [dispatch, referenceDataRef, referenceLoadedRef]);

    // Grouptype change handler
    const changeType = (ev: any) => {
        refreshActivity();
        setType(ev.target.value)

        Object.keys(types).forEach((tpe: any) => {
            if (ev.target.value.indexOf(tpe) > 0) {
                /* istanbul ignore next */
                historyNav.push("/groupings/" + tpe);
            }
        })
    }

    // filter group by type
    useEffect(() => {
        setGroups(groupingsDataRef.filter((group: any) => group.type === type.substring(type.lastIndexOf("entity=") + 7, type.indexOf("&pageIndex"))))
    }, [type, groupingsDataRef, groupingsOrgRef, viewAs.org])

    useEffect(() => {
        return () => {
            dispatch({ type: "UNLOCK_BUCKET" });
        }
    }, [dispatch]);

    // handler changed in treeVals
    /* istanbul ignore next */
    const groupItem = (data: any) => {
        refreshActivity();
        if (data.length <= 0) {
            return;
        }

        setEditableGroup((prev: any) => {
            const p = [...prev.values];

            const aMap: any = {};
            if (type === "apap") {
                references[window.ENV.references.airport].forEach((item: any) => {
                    aMap[item.code] = item;
                });
            }

            for (let k = 0; k < data.length; k++) {
                let val = "";

                if (type === "apap" && data[k].code.length === 6) {
                    const a1 = data[k].code.substring(0, 3);
                    const a2 = data[k].code.substring(3, 6);
                    console.log("MAP", a1, a2, aMap[a1], aMap[a2])
                    if (aMap[a1] && aMap[a2]) {
                        val = data[k].code;
                    }
                } else if (type !== "apap") {
                    val = data[k].code;// + (data[k]['region_type'] ? ":" + data[k]['region_type'] : '')).trim();
                }

                if (val && val.length > 0 && p.indexOf(val) < 0 && val !== "undefined") {
                    p.push(val);
                }
            }

            return { ...prev, values: [...p] };
        })
    }

    // Edit and Clone handler
    const editGroup = (group: any, type: string) => {
        const current: any = leftWrapRef.current;
        current.classList.remove('min');
        refreshActivity();
        if (type === "edit") {
            setEditableGroup({ ...group, age: -1, shared: (group.org === Const.ARC_ROLE ? "A" : "") + group.shared });
        }
        if (type === "clone") {
            setEditableGroup({
                "age": 0,
                "name": "",
                "priority": group.priority,
                "type": group.type,
                "shared": "0",
                "org": isInternalAdminOrUser() ? Const.ARC_ROLE : (getOts()?.idToken?.orgName || getOts()?.accessToken?.claims?.org),
                "values": group.values,
                "owner_id": (getOts()?.idToken?.sub || getOts()?.idToken?.claims?.preferred_username)?.toLowerCase()
            });
        }
    }

    const checkLockBucket = () => {
        if (lockBucketRef) {
            setTimeout(() => {
                historyNav.push('/ref/agency')
                dispatch({ type: "CLEAR_BUCKET" });
            }, 1000)
        }
    }

    // Saving GROUP
    const saveGrouping = () => {
        const filtered = () => {
            const eg = { ...editableGroup };
            if (isInternalAdminOrUser()) {
                if (["0", "A2"].indexOf(eg.shared) >= 0) {
                    eg.org = Const.ARC_ROLE;
                } else {
                    eg.org = viewAs?.org;
                }
            } else {
                eg.org = (getOts()?.idToken?.orgName || getOts()?.accessToken?.claims?.org);
            }

            eg.shared = eg.shared.replace("A", "");

            setEditableGroup((prev: any) => {
                return { ...prev, org: eg.org, shared: eg.shared };
            });
            return Object.fromEntries(Object.entries(eg).filter(([key]) => key !== "age" && key !== "top" && key !== "description" && key !== "code"))
        }

        if (editableGroup.age === 0) {
            editableGroup.age = -1;
            dispatch(setSavedGroupingsAction([filtered()], "post"));
        } else {
            dispatch(setSavedGroupingsAction([filtered()], "put"));
        }
        setEditableGroup({})

        checkLockBucket();

        /* istanbul ignore next */
        window.scrollTo(0, 0);
    }

    // delete group from db handler
    const removeGroup = (group: any) => {
        dispatch(deleteSavedGroupingsAction(group.Id, group.name))
        setGroups(prev => {
            return [...prev].filter((item: any) => item.Id !== group.Id);
        })
    }

    // share group handler
    const shareGroup = (group: any) => {

        setGroups(prev => {
            for (let k = 0; k < prev.length; k++) {
                if (prev[k].Id === group.Id) {
                    prev[k] = { ...group, "shared": "2" };
                }
            }
            return [...prev];
        })

        const filtered = () => {
            const keys = ["shared", "Id", "name"]
            const filterGroup = groups.filter(item => item.Id === group.Id)
            return Object.fromEntries(Object.entries(filterGroup[0]).filter(([key]) => keys.includes(key)))
        }

        dispatch(setSavedGroupingsAction([filtered()], "put"));
    }

    // delete item from list handler
    const removeItem = (value: string) => {
        setEditableGroup((prev: any) => {
            prev.values = prev.values.filter((item: string) => item !== value);
            return { ...prev };
        })
    }

    let treeVals: any = [];

    /* istanbul ignore next */
    const onTreeChange = (currentNode: any, selectedNodes: any) => {
        treeVals = [...selectedNodes];
    }

    /* istanbul ignore next */
    // const onBlur = () => {
    //     setEditableGroup((prev: any) => {
    //         for (let k = 0; k < treeVals.length; k++) {
    //             const code = treeVals[k].code + (treeVals[k]['region_type'] ? ":" + treeVals[k]['region_type'] : '')
    //             if (prev.values.indexOf(code) < 0) {
    //                 prev.values.push(code);
    //             }
    //         }
    //         return prev;
    //     })
    //     setGroups(prev => {
    //         return [...prev];
    //     })
    // }


    // adding NewGroup handler
    const addNewGroup = useCallback(() => {
        const current: any = leftWrapRef.current;
        current.classList.remove('min');
        refreshActivity();
        setEditableGroup(
            {
                name: "",
                priority: "0",
                type: type.substring(type.lastIndexOf("entity=") + 7, type.indexOf("&pageIndex")),
                shared: "0",
                org: isInternalAdminOrUser() ? Const.ARC_ROLE : (getOts()?.idToken?.orgName || getOts()?.accessToken?.claims?.org),
                values: [],
                user_id: (getOts()?.idToken?.sub || getOts()?.idToken?.claims?.preferred_username)?.toLowerCase(),
                age: 0
            }
        );
    }, [type]);

    useEffect(() => {
        setViewAs(customersRef.viewAs);
        dispatch(getSavedGroupingsAction())
        if (customersRef.customer?.data_content.indexOf("AGGREGATION") >= 0 && type.indexOf("airline") >= 0) {
            setType(window.ENV.references.airport)
            historyNav.push("/groupings/airport")
        }
    }, [customersRef.viewAs, dispatch, customersRef.customer, type, historyNav]);

    useEffect(() => {
        if (lockBucketRef) {
            addNewGroup();
            setEditableGroup((prev: any) => {
                return { ...prev, values: lookupBucketRef.map((item: any) => item?.code.toString()) }
            })
        }
    }, [lookupBucketRef, lockBucketRef, addNewGroup])

    return (
        <React.Fragment>
            <Feedback page="Custom Groupings" />
            <FDS.FDSLevel data-testid="grouping-container" type="two" name="groupingsPage" mainTitle="Custom Groupings" subTitle="Create, manage and share custom groups">
                <Container className="fds-pl-container first float">
                    <Row className="fds-pl-column p-0 shadow">
                        <Col className="leftWrap" ref={leftWrapRef}>
                            <div className="minimize">
                                <Button variant="primary" size="sm" onClick={(ev: any) => {
                                    ev?.currentTarget?.parentElement?.parentElement?.classList?.toggle('min')
                                }}>
                                    <i className="fds-glyphs-arrow-left3"></i>
                                </Button>
                            </div>
                            <div className="blankable pb-3 mb-3">
                                <div className="card p-3">
                                    <Form.Group className="form-group">
                                        <h3>Select a Group Type</h3>
                                        <div className="select">
                                            <Form.Select aria-label='Select group type' data-testid="select-group-type" as="select" value={type} onChange={(ev) => { dispatch({ type: "UNLOCK_BUCKET" }); setEditableGroup({}); changeType(ev); }}>
                                                <option value={window.ENV.references.airport}>{types['airport']}</option>
                                                {customersRef.customer.data_content.indexOf("AGGREGATION") < 0 && <option value={window.ENV.references.airline}>{types['airline']}</option>}
                                                <option value={window.ENV.references.region}>{types['region']}</option>
                                                <option value="apap">{types['apap']}</option>
                                                <option value={window.ENV.references.agency}>{types['agency']}</option>
                                            </Form.Select>
                                        </div>
                                    </Form.Group>
                                </div>
                                {editableGroup.age !== undefined ? <div data-testid="grouping-create-new-container">
                                    <hr />

                                    <Form.Group controlId="name">
                                        <Form.Label>Name</Form.Label>
                                        <Form.Control data-testid="add-group-input" type="text" value={editableGroup.name} autoComplete="off" required={true} onChange={(ev) => setEditableGroup((prev: any) => {
                                            return { ...prev, name: ev.target.value }
                                        })} />
                                        {groups.filter(item =>
                                            item.type === type.substring(type.lastIndexOf("entity=") + 7, type.indexOf("&pageIndex")) &&
                                            (item.name.toLowerCase() === editableGroup.name.toLowerCase()) && item.Id !== editableGroup.Id
                                        ).length > 0 ?
                                            <Form.Text className="danger pb-1">Group Name already exists</Form.Text> :
                                            null
                                        }
                                    </Form.Group>

                                    {/* /* istanbul ignore next */ }
                                    {/* <Form.Group className='mt-3' controlId="priority">
                                        <Form.Label >Priority</Form.Label>
                                        <Form.Control as="select" disabled={!editableGroup.user_id} value={editableGroup.priority} onChange={(ev) => setEditableGroup((prev: any) => {
                                            return { ...prev, priority: ev.target.value }
                                        })}>
                                            {editableGroup.user_id ? (
                                                <React.Fragment>
                                                    <option value="0">{priorityRef[0]}</option>
                                                    <option value="1">{priorityRef[1]}</option>
                                                    <option value="2">{priorityRef[2]}</option>
                                                </React.Fragment>) : <option value="0">{priorityRef[editableGroup.priority]}</option>}

                                        </Form.Control>
                                        <Form.Text></Form.Text>
                                    </Form.Group> */}

                                    <fieldset disabled={!editableGroup.user_id}>
                                        <Form.Group className='mt-3' controlId="values">
                                            <Form.Label>Values</Form.Label>
                                            {editableGroup.user_id ? type === window.ENV.references.region ?
                                                <DropdownTreeSelect
                                                    data={references[type] ? references[type].filter((ref: any) => !ref.top) : []}
                                                    keepTreeOnSearch={true}
                                                    onFocus={() => console.log("FOCUS")}
                                                    onBlur={() => groupItem(treeVals)}
                                                    onChange={(currentNode, selectedNodes) => onTreeChange(currentNode, selectedNodes)} /> :
                                                referenceLoadedRef.status || type !== window.ENV.references.agency ? <FDS.FDSTypeahead
                                                    dataList={references[type] ? references[type].filter((ref: any) => !ref.top) : []}
                                                    minChar={[window.ENV.references.airport, window.ENV.references.agency].indexOf(type) >= 0 ? 2 : 0}
                                                    clearOnSelect={true}
                                                    labelKey="description"
                                                    codeKey="code"
                                                    matchedOnly={type !== "apap" ? true : false}
                                                    truncateCodes={true}
                                                    onChange={(data) => groupItem(data)} /> :
                                                    <div className="p-3">
                                                        <Spinner animation="border" role="status" variant="primary" size="sm">
                                                            <span className="sr-only"></span>
                                                        </Spinner> &nbsp;
                                                        Loading Agency Reference Data...
                                                    </div>
                                                : null}

                                            {editableGroup?.values?.length > 0 ? <div className="ps-3 pe-3 pt-2 pb-2 mt-1" style={{ border: "1px solid #ccc", borderRadius: "4px", maxHeight: '200px', overflow: 'auto', background: 'white' }}>
                                                {editableGroup?.values?.map((value: any, j: number) => {
                                                    return (
                                                        <div className="fds-tag primary mt-1 mb-1" key={j}>
                                                            {value?.indexOf(":") > 0 ? value?.substring(0, value?.indexOf(":")) : value}
                                                            {editableGroup.user_id && <i className="fds-glyphs-clear" onClick={() => removeItem(value)}></i>}
                                                        </div>
                                                    )
                                                })}
                                            </div> : null}
                                        </Form.Group>
                                    </fieldset>

                                    <fieldset disabled={!editableGroup.user_id}>
                                        <Form.Group className='form-group mt-3' controlId="shared">
                                            <Form.Label>Sharing</Form.Label>
                                            <div className="select">
                                                <Form.Select aria-label='Select Sharing option' data-testid="sharing-select-box" as="select" disabled={!editableGroup.user_id} value={editableGroup.shared} onChange={(ev) => setEditableGroup((prev: any) => {
                                                    return { ...prev, shared: ev.target.value }
                                                })}>
                                                    <option value="0">Keep Private</option>
                                                    {
                                                        isInternalAdminOrUser() ?
                                                            <option aria-label='arc users' value="A2">Share with all ARC users</option> :
                                                            null
                                                    }
                                                    {[viewAs.orgName, viewAs.org].indexOf(Const.ARC_ROLE) < 0 ? <option aria-label='all users' value="2">Share with all {viewAs.orgName || customersRef.customer?.name || getOts()?.idToken?.orgName || getOts()?.accessToken?.claims?.org} users</option> : null}
                                                </Form.Select>
                                            </div>
                                        </Form.Group>
                                    </fieldset>

                                    <Row>
                                        <Col sm={5}>
                                            <div className="pt-3">
                                                <Button variant="secondary" onClick={() => setEditableGroup({})}>Clear</Button>
                                            </div>
                                        </Col>
                                        <Col sm={7}>
                                            <div className="pt-3" style={{ textAlign: "right" }}>
                                                <Button variant="primary" disabled={!editableGroup.name || editableGroup.values.length <= 0 ||
                                                    (groups.filter(item => item.type ===
                                                        type.substring(type.lastIndexOf("entity=") + 7, type.indexOf("&pageIndex")) &&
                                                        item.name.toLowerCase() === editableGroup.name.toLowerCase() && item.Id !== editableGroup.Id).length > 0)}
                                                    onClick={() => saveGrouping()}>Save Grouping</Button>
                                            </div>
                                        </Col>
                                    </Row>


                                </div> : null}
                            </div>
                        </Col>
                        <Col className="pt-3">
                            <div className="fds-table-wrap">
                                <Card>
                                    <Row>
                                        <Col>
                                            <div className="ps-3">
                                                <h3 className="mt-3">My Groupings</h3>
                                                {/* <h3 className="mt-3">{`My ${types[type.substring(type.lastIndexOf("entity=") + 7, type.indexOf("&pageIndex"))]}s`}</h3> */}
                                                {/* <p className='type-body-small-on-light'>{groups && groups.filter(group => group.shared === "2")[0]?.org}/{getOtsLength() > 0 ? getOts()?.idToken?.name : ""} Groups</p> */}
                                            </div>
                                        </Col>
                                        <Col>
                                            <div style={{ textAlign: 'right' }}>
                                                <Button className="m-3" variant="primary" onClick={() => addNewGroup()}>Add New Group</Button>
                                            </div>
                                        </Col>
                                    </Row>

                                    {groups.filter(g => g.owner_id?.toLowerCase() === (getOts()?.idToken?.sub || getOts()?.idToken?.claims?.preferred_username)?.toLowerCase() && g.shared === "0").length > 0 ?
                                        <Table data-testid="fds-table-main" className="fds-table table-striped" style={{ border: 'none' }}>
                                            <thead>
                                                <tr>
                                                    <th>Owner</th>
                                                    <th>Group Name</th>
                                                    {/* <th>Priority</th> */}
                                                    <th>Name / Code</th>
                                                    <th className="right pe-3" style={{ width: '80px' }}>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {groups
                                                    .filter(g =>
                                                        g.owner_id?.toLowerCase() === (getOts()?.idToken?.sub || getOts()?.idToken?.claims?.preferred_username)?.toLowerCase() &&
                                                        g.shared === "0"
                                                    )
                                                    .sort((a: any, b: any) => a.name < b.name ? -1 : 1).map((group, i) => {
                                                        return (
                                                            <tr key={i} style={{ background: group.age === 0 ? "#e4e5e7" : "inherit" }}>
                                                                <td style={{ minWidth: '100px', whiteSpace: 'nowrap' }}>
                                                                    {group.email?.toLowerCase() || group.owner_id?.toLowerCase()}
                                                                </td>
                                                                <td style={{ verticalAlign: "top", whiteSpace: 'nowrap' }}>
                                                                    {group.name}
                                                                </td>
                                                                {/* <td style={{ verticalAlign: "top" }}>
                                                                {priorityRef[group.priority]}
                                                            </td> */}
                                                                <td style={{ verticalAlign: "top" }}>
                                                                    <div style={{ maxHeight: '200px', overflow: 'auto' }}>
                                                                        {group.values.map((value, j) => {
                                                                            return (
                                                                                <div className="fds-tag primary mt-1 mb-1" key={j}>
                                                                                    {value.indexOf(":") > 0 ? value.substring(0, value.indexOf(":")) : value}
                                                                                </div>
                                                                            )
                                                                        })}
                                                                    </div>
                                                                </td>
                                                                <td className="editMenu">
                                                                    <Dropdown data-testid="main-table-edit-menu">
                                                                        <Dropdown.Toggle variant="link"><i className="fds-glyphs-more2"></i></Dropdown.Toggle>

                                                                        <Dropdown.Menu>
                                                                            <Dropdown.Item href="#" onClick={() => editGroup(group, "edit")}><i className="fds-glyphs-pencil4" />Edit</Dropdown.Item>
                                                                            <Dropdown.Item href="#" onClick={() => removeGroup(group)}><i className="fds-glyphs-bin2" />Remove</Dropdown.Item>
                                                                            <Dropdown.Item href="#" onClick={() => editGroup(group, "clone")}><i className="fds-glyphs-file-plus" />Clone</Dropdown.Item>
                                                                            <Dropdown.Item href="#" onClick={() => shareGroup(group)}><i className="fds-glyphs-upload" />Share</Dropdown.Item>
                                                                        </Dropdown.Menu>
                                                                    </Dropdown>
                                                                </td>
                                                            </tr>
                                                        )
                                                    })}
                                            </tbody>
                                        </Table> :

                                        <Alert data-testid="table-main-no-data" className="m-3" variant="primary">
                                            <div className="icon"><i className="fds-glyphs-info2"></i></div>
                                            <div className="body">There are currently no groups available.</div>
                                        </Alert>
                                    }
                                </Card>

                                <div className="pt-3 pb-3"></div>

                                <Card className='mb-3'>

                                    <div className="ps-3">
                                        <h3 className="mt-3">Shared Groupings</h3>
                                        {/* <h3 className="mt-3">{types[type.substring(type.lastIndexOf("entity=") + 7, type.indexOf("&pageIndex"))]}</h3> */}
                                        {/* <p className='type-body-small-on-light'>Global {groups && groups.filter(group => group.shared === "2")[0]?.org} Groups</p> */}
                                    </div>
                                    {groups.filter(g => g.shared !== "0").length > 0 ?
                                        <Table data-testid="fds-table-shared" className="fds-table table-striped" style={{ border: 'none' }}>
                                            <thead>
                                                <tr>
                                                    <th>Owner</th>
                                                    <th>Group Name</th>
                                                    {/* <th>Priority</th> */}
                                                    <th>Name / Code</th>
                                                    <th className="right pe-3" style={{ width: '80px' }}>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    groups
                                                        .filter(g => g.shared !== "0")
                                                        .sort((a, b) => ((a.org === Const.ARC_ROLE ? "A" : "") + a.shared) < ((b.org === Const.ARC_ROLE ? "A" : "") + b.shared) ? 1 : -1)
                                                        .map((group, i) => {
                                                            return (
                                                                <tr key={i} style={{ background: group.age === 0 ? "#e4e5e7" : "inherit" }}>
                                                                    <td style={{ minWidth: '100px', whiteSpace: 'nowrap' }}>
                                                                        {group.shared === "1" ? <span>
                                                                            <i className="fds-glyphs-bookmark2" /> System
                                                                        </span> : null}
                                                                        {group.org === Const.ARC_ROLE ? <span>
                                                                            <img className='arcFilter' src="https://www2.arccorp.com/globalassets/arc-logos/corporate-logos/arc-logo-l-teal.png" alt="ARC" style={{ height: "14px" }} />
                                                                        </span> : (group.shared === "2" ? (customersRef.customer?.name || group.org) : group.org)}
                                                                    </td>
                                                                    <td style={{ verticalAlign: "top", whiteSpace: 'nowrap' }}>
                                                                        {group.name}
                                                                    </td>
                                                                    {/* <td style={{ verticalAlign: "top" }}>
                                                                {priorityRef[group.priority]}
                                                            </td> */}
                                                                    <td style={{ verticalAlign: "top" }}>
                                                                        <div style={{ maxHeight: '200px', overflow: 'auto' }}>
                                                                            {group.values.map((value, j) => {
                                                                                return (
                                                                                    <div className="fds-tag primary mt-1 mb-1" key={j}>
                                                                                        {value.indexOf(":") > 0 ? value.substring(0, value.indexOf(":")) : value}
                                                                                    </div>
                                                                                )
                                                                            })}
                                                                        </div>
                                                                    </td>
                                                                    <td className="editMenu">
                                                                        <Dropdown data-testid="edit-menu-items">
                                                                            <Dropdown.Toggle data-testid="edit-menu-items-btn" variant="link"><i className="fds-glyphs-more2"></i></Dropdown.Toggle>

                                                                            <Dropdown.Menu data-testid="edit-menu-items-list">
                                                                                <Dropdown.Item href="#" onClick={() => editGroup(group, "clone")}><i className="fds-glyphs-file-plus" />Clone</Dropdown.Item>
                                                                                {(getOts()?.idToken?.sub || getOts()?.idToken?.claims?.preferred_username)?.toLowerCase() === group.owner_id?.toLowerCase() ?
                                                                                    <React.Fragment>
                                                                                        <Dropdown.Item href="#" onClick={() => editGroup(group, "edit")}><i className="fds-glyphs-pencil4" />Edit</Dropdown.Item>
                                                                                        <Dropdown.Item href="#" onClick={() => removeGroup(group)}><i className="fds-glyphs-bin2" />Remove</Dropdown.Item>
                                                                                    </React.Fragment>
                                                                                    : null}

                                                                            </Dropdown.Menu>
                                                                        </Dropdown>
                                                                    </td>
                                                                </tr>
                                                            )
                                                        })
                                                }
                                            </tbody>
                                        </Table> :

                                        <Alert className="m-3" variant="primary">
                                            <div className="icon"><i className="fds-glyphs-info2"></i></div>
                                            <div className="body">There are currently no groups available.</div>
                                        </Alert>
                                    }
                                </Card>
                            </div>
                        </Col>

                    </Row>
                </Container>
                <div></div>
            </FDS.FDSLevel>
        </React.Fragment>
    )
}
