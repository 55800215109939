export const savedAllTemplatesRef = (state = [], action: any) => {
    switch (action.type) {
        case "ALL_SAVED_TEMPLATES":
            return action.data;
        default:
            return state;
    }
};

export const savedTemplatesRef = (state = [], action: any) => {
    switch (action.type) {
        case "SAVED_TEMPLATES":
            return action.data;
        default:
            return state;
    }
};

export const rerunTemplateRef = (state = {}, action: any) => {
    switch (action.type) {
        case "RERUN_TEMPLATE":
            return action.data;
        default:
            return state;
    }
};